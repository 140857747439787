import React from 'react';
import { Container, Header } from '@awsui/components-react';

import './LineChart.css';

export function ContainerWithHeader(props) {
  const {
    label,
    children,
  } = props;

  return (
    <div className="lineChartWrapper">
      <Container
        header={(
          <Header variant="h2" description="">
            {label}
          </Header>
        )}
      >
        {children}
      </Container>
    </div>
  );
}
