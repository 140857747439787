import gql from 'graphql-tag';

export default gql(`
query($seasonId: String!, $filter: TableRiderFilterInput, $limit: Int, $nextToken: String) {
  listRiders(seasonId: $seasonId, filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      FirstName
      LastName
      BirthDate
      UCIID
      Gender
      TrainingLocation
      LeagueCat
      Nationality
      SeasonTitle
      HeightCm
      WeightKg
      RestHrBpm
      MaxHrBpm
      Flying200
      GearingForFlying200
      PowerPeakW
      Power5sW
      Power15sW
      Power30sW
      Power60sW
      Power120sW
      Power180sW
      Power300sW
      Power600sW
      Power1200sW
      Power1800sW
      Power3600sW
      NOC
      Bib
      SeasonID
      Confidence {
        cadence
        power
        heartrate
        speed
      }
      wordpress {
        id
        portraitImageURISmall
      }
    }
    nextToken
  }
}`);

// uciID
// firstName
// lastName
// countryCode
// countryName
// age
// birthdate
// height
// weight
// biography
// portraitImageURI
