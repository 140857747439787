import React from 'react';
import { Alert, Button } from '@awsui/components-react';
import { useHistory, useLocation } from 'react-router-dom';

import './Layout.scss';
import logo from '../images/uci-logo.png';
import ErrorBoundary from './ErrorBoundry';

export function LayoutBackButton({ className }) {
  const location = useLocation();
  const history = useHistory();

  return (
    location.pathname !== '/' && (
      <Button
        className={`btn h-8 ml-16 ${className}`}
        style={{ width: '104px' }}
        onClick={() => {
          history.goBack();
        }}
      >
        &lt;&lt; Back
      </Button>
    )
  );
}

export default function Layout({
  title,
  titleBold,
  subtitle,
  hide,
  operation,
  children,
  hideBackButton,
  ...props
}) {
  return (
    <ErrorBoundary>
      <div
        {...props}
        className={`container py-14 ${props.className} `}
      >
        {hide?.header !== true && (
          <div className="row p-2 w-full">
            <div className="col-auto me-auto">
              <h2 className="text-gray-dark text-2xl font-medium">{title}</h2>
            </div>
            {!hideBackButton && (
              <div className="col-auto">
                <LayoutBackButton />
              </div>
            )}
          </div>
        )}

        {operation?.status?.isLoading && (
          <div
            className="absolute z-10 bg-white opacity-50 h-screen w-full top-0 left-0 items-center justify-center flex"
          />
        )}

        {operation?.status?.error?.message?.length && (
          <Alert className="mb-4" visible type="error">
            An error occurred:
            {' '}
            {operation?.status?.error?.message}
          </Alert>
        )}

        {!operation?.status?.isLoading && children}
      </div>
      {operation?.status?.isLoading && (
        <img
          alt="loader"
          className="position-absolute top-50 start-50 translate-middle blob z-20 "
          src={logo}
        />
      )}
    </ErrorBoundary>
  );
}
