import gql from 'graphql-tag';

export default gql(`
query($eventId: String!) {
  getRacesForEvent(eventId: $eventId) {
    items {
      Date
      Distance
      EventID
      EventName
      Gender
      Heat
      Laps
      League
      Message
      RaceID
      RaceName
      RaceStatus
      RaceType
      Round
      SeasonID
      StartTime
      TimeStamp
      TotalHeats
      TotalRounds
    }
  }
}`);
