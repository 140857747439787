import { combineReducers } from 'redux';

import {
  API_CLEAR_LIVE_DATA,
  API_GET_ELIMINATED_RIDERS,
  API_GET_ELIMINATED_RIDERS_ERROR,
  API_GET_ELIMINATED_RIDERS_SUCCESS,
  API_GET_EVENTS,
  API_GET_EVENTS_ERROR,
  API_GET_EVENTS_SUCCESS,
  API_GET_LIVE_DATA,
  API_GET_LIVE_DATA_ERROR,
  API_GET_LIVE_DATA_SUCCESS,
  API_GET_LIVE_RACE,
  API_GET_LIVE_RACE_ERROR,
  API_GET_LIVE_RACE_SUCCESS,
  API_GET_LIVE_RIDER_DATA,
  API_GET_LIVE_RIDER_DATA_ERROR,
  API_GET_LIVE_RIDER_DATA_SUCCESS,
  API_GET_RACE_RESULTS,
  API_GET_RACE_RESULTS_ERROR,
  API_GET_RACE_RESULTS_STATS,
  API_GET_RACE_RESULTS_STATS_ERROR,
  API_GET_RACE_RESULTS_STATS_SUCCESS,
  API_GET_RACE_RESULTS_SUCCESS,
  API_GET_RACES,
  API_GET_RACES_ERROR,
  API_GET_RACES_SUCCESS,
  API_GET_RIDER_DETAILS,
  API_GET_RIDER_DETAILS_ERROR,
  API_GET_RIDER_DETAILS_SUCCESS,
  API_GET_COMPARED_RIDER_DETAILS,
  API_GET_COMPARED_RIDER_DETAILS_ERROR,
  API_GET_COMPARED_RIDER_DETAILS_SUCCESS,
  API_GET_RIDERS_IN_RACE,
  API_GET_RIDERS_IN_RACE_ERROR,
  API_GET_RIDERS_IN_RACE_SUCCESS,
  API_GET_RIDERS_LIST,
  API_GET_RIDERS_LIST_ERROR,
  API_GET_RIDERS_LIST_SUCCESS,
  API_GET_SEASONS,
  API_GET_SEASONS_ERROR,
  API_GET_SEASONS_SUCCESS,
  API_GET_STANDINGS,
  API_GET_STANDINGS_ERROR,
  API_GET_STANDINGS_SUCCESS,
  API_GET_UI_SETTINGS,
  API_GET_UI_SETTINGS_ERROR,
  API_GET_UI_SETTINGS_SUCCESS,
  API_LIST_COUNTRIES,
  API_LIST_COUNTRIES_ERROR,
  API_LIST_COUNTRIES_SUCCESS,
  API_LIVE_RIDER_DATA_POLLING_SUCCESS,
  API_POLLING_SUCCESS,
  API_SET_LASTEST_TIMESTAMP,
  API_START_POLLING,
  API_STOP_POLLING,
  API_TOGGLE_RIDER_CONFIDENCE_SUCCESS,
  API_GET_RIDER_STATS,
  API_GET_RIDER_STATS_SUCCESS,
  API_GET_RIDER_STATS_ERROR,
  API_GET_COMPARED_RIDER_STATS,
  API_GET_COMPARED_RIDER_STATS_SUCCESS,
  API_GET_COMPARED_RIDER_STATS_ERROR,
} from '../constants/api.constants';
import { getStateError, getStateInProgress, getStateSuccess } from '../utils/reducers/state.util';

export const initialAPIState = {
  api: {
    races: [],
    events: [],
    riders: [],
    eliminatedRiders: [],
    settings: {},
    raceResults: {},
    raceResultsStats: {},
    liveData: {},
    standings: {},
    liveRace: {},
    curRace: [],
    polling: false,
    latestTimeStamp: undefined,
    riderStats: [],
    comparedRiderStats: [],
  },
};

export const apiReducers = combineReducers({
  polling: (state = false, action) => {
    switch (action.type) {
      case API_START_POLLING: {
        return true;
      }
      case API_STOP_POLLING: {
        return false;
      }
      default: {
        return state;
      }
    }
  },
  events: (state = [], action) => {
    switch (action.type) {
      case API_GET_EVENTS: {
        return {
          ...state,
          ...getStateInProgress(),
        };
      }
      case API_GET_EVENTS_SUCCESS: {
        const { response } = action.payload;
        return {
          ...state,
          ...getStateSuccess(response),
        };
      }
      case API_GET_EVENTS_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
          current: state?.current,
        };
      }
      default: {
        return state;
      }
    }
  },
  settings: (state = [], action) => {
    switch (action.type) {
      case API_GET_UI_SETTINGS: {
        return {
          ...state,
          ...getStateInProgress(),
        };
      }
      case API_GET_UI_SETTINGS_SUCCESS: {
        const response = action.payload;
        return {
          ...state,
          ...getStateSuccess(response),
        };
      }
      case API_GET_UI_SETTINGS_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
          current: state?.current,
        };
      }
      default: {
        return state;
      }
    }
  },
  standings: (state = [], action) => {
    switch (action.type) {
      case API_GET_STANDINGS: {
        return {
          ...state,
          ...getStateInProgress(),
        };
      }
      case API_GET_STANDINGS_SUCCESS: {
        const response = action.payload;
        return {
          ...state,
          ...getStateSuccess(response),
        };
      }
      case API_GET_STANDINGS_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
          current: state?.current,
        };
      }
      default: {
        return state;
      }
    }
  },
  seasons: (state = [], action) => {
    switch (action.type) {
      case API_GET_SEASONS: {
        return {
          ...state,
          ...getStateInProgress(),
        };
      }
      case API_GET_SEASONS_SUCCESS: {
        const { response } = action.payload;
        return {
          ...state,
          ...getStateSuccess(response),
        };
      }
      case API_GET_SEASONS_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
          current: state?.current,
        };
      }
      default: {
        return state;
      }
    }
  },
  riders: (state = [], action) => {
    switch (action.type) {
      case API_GET_RIDERS_LIST: {
        return {
          ...state,
          ...getStateInProgress(),
          current: state?.current,
        };
      }
      case API_GET_RIDERS_LIST_SUCCESS: {
        const { response } = action.payload;
        return {
          ...state,
          ...getStateSuccess({
            items: [
              ...(response?.items || []),
            ],
            nextToken: response?.nextToken,
          }),
        };
      }
      case API_GET_RIDERS_LIST_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
          current: state?.current,
        };
      }
      default: {
        return state;
      }
    }
  },
  riderDetails: (state = [], action) => {
    switch (action.type) {
      case API_GET_RIDER_DETAILS: {
        const { uciId } = action.payload;
        return {
          ...state,
          [uciId]: getStateInProgress(),
        };
      }
      case API_TOGGLE_RIDER_CONFIDENCE_SUCCESS: {
        const {
          uciId,
          confidence,
        } = action.payload;
        return {
          ...state,
          [uciId]: getStateSuccess({
            ...state[uciId]?.current,
            confidence,
          }),
        };
      }
      case API_GET_RIDER_DETAILS_SUCCESS: {
        const {
          uciId,
          rider,
          riderStartLists,
          riderResultsInRaces,
          confidence,
        } = action.payload;
        return {
          ...state,
          [uciId]: getStateSuccess({
            confidence,
            rider,
            riderStartLists,
            riderResultsInRaces,
          }),
        };
      }
      case API_GET_RIDER_DETAILS_ERROR: {
        const {
          uciId,
          error,
        } = action.payload;
        return {
          ...state,
          [uciId]: getStateError(error),
        };
      }
      default: {
        return state;
      }
    }
  },
  comparedRiderDetails: (state = [], action) => {
    switch (action.type) {
      case API_GET_COMPARED_RIDER_DETAILS: {
        const { uciId } = action.payload;
        return {
          ...state,
          [uciId]: getStateInProgress(),
        };
      }
      case API_TOGGLE_RIDER_CONFIDENCE_SUCCESS: {
        const {
          uciId,
          confidence,
        } = action.payload;
        return {
          ...state,
          [uciId]: getStateSuccess({
            ...state[uciId]?.current,
            confidence,
          }),
        };
      }
      case API_GET_COMPARED_RIDER_DETAILS_SUCCESS: {
        const {
          uciId,
          rider,
          riderStartLists,
          riderResultsInRaces,
          confidence,
        } = action.payload;
        return {
          ...state,
          [uciId]: getStateSuccess({
            confidence,
            rider,
            riderStartLists,
            riderResultsInRaces,
          }),
        };
      }
      case API_GET_COMPARED_RIDER_DETAILS_ERROR: {
        const {
          uciId,
          error,
        } = action.payload;
        return {
          ...state,
          [uciId]: getStateError(error),
        };
      }
      default: {
        return state;
      }
    }
  },
  raceResults: (state = [], action) => {
    switch (action.type) {
      case API_GET_RACE_RESULTS: {
        const { raceId } = action.payload;
        return {
          ...state,
          [raceId]: getStateInProgress(),
        };
      }
      case API_GET_RACE_RESULTS_SUCCESS: {
        const {
          raceId,
          response,
        } = action.payload;
        return {
          ...state,
          [raceId]: getStateSuccess(response),
        };
      }
      case API_GET_RACE_RESULTS_ERROR: {
        const {
          raceId,
          error,
        } = action.payload;
        return {
          ...state,
          [raceId]: getStateError(error),
        };
      }
      default: {
        return state;
      }
    }
  },
  raceResultsStats: (state = [], action) => {
    switch (action.type) {
      case API_GET_RACE_RESULTS_STATS: {
        const { raceId } = action.payload;
        return {
          ...state,
          [raceId]: getStateInProgress(),
        };
      }
      case API_GET_RACE_RESULTS_STATS_SUCCESS: {
        const {
          raceId,
          response,
        } = action.payload;
        return {
          ...state,
          [raceId]: getStateSuccess(response),
        };
      }
      case API_GET_RACE_RESULTS_STATS_ERROR: {
        const {
          raceId,
          error,
        } = action.payload;
        return {
          ...state,
          [raceId]: getStateError(error),
        };
      }
      default: {
        return state;
      }
    }
  },
  races: (state = [], action) => {
    switch (action.type) {
      case API_GET_RACES: {
        return {
          ...state,
          ...getStateInProgress(),
        };
      }
      case API_GET_RACES_SUCCESS: {
        const { response } = action.payload;

        return {
          ...state,
          ...getStateSuccess(
            response.sort(
              (a, b) => new Date(a.StartTime) - new Date(b.StartTime),
            ),
          ),
        };
      }
      case API_GET_RACES_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
          current: state?.current,
        };
      }
      default: {
        return state;
      }
    }
  },
  liveRace: (state = [], action) => {
    switch (action.type) {
      case API_GET_LIVE_RACE: {
        return {
          ...state,
          ...getStateInProgress(),
        };
      }
      case API_GET_LIVE_RACE_SUCCESS: {
        const { response } = action.payload;

        return {
          ...state,
          ...getStateSuccess(response),
        };
      }
      case API_GET_LIVE_RACE_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
          current: state?.current,
        };
      }
      default: {
        return state;
      }
    }
  },
  countries: (state = [], action) => {
    switch (action.type) {
      case API_LIST_COUNTRIES: {
        return {
          ...state,
          ...getStateInProgress(),
        };
      }
      case API_LIST_COUNTRIES_SUCCESS: {
        const { response } = action.payload;

        return {
          ...state,
          ...getStateSuccess(response),
        };
      }
      case API_LIST_COUNTRIES_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
        };
      }
      default: {
        return state;
      }
    }
  },
  eliminatedRiders: (state = [], action) => {
    switch (action.type) {
      case API_GET_ELIMINATED_RIDERS: {
        return {
          ...state,
          ...getStateInProgress(),
          current: state?.current,
        };
      }
      case API_GET_ELIMINATED_RIDERS_SUCCESS: {
        return {
          ...state,
          ...getStateSuccess({
            items: [
              ...(action.payload || []),
            ],
          }),
        };
      }
      case API_GET_ELIMINATED_RIDERS_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
          current: state?.current,
        };
      }
      default: {
        return state;
      }
    }
  },
  curRace: (state = [], action) => {
    switch (action.type) {
      case API_GET_RIDERS_IN_RACE: {
        return {
          ...state,
          ...getStateInProgress(),
        };
      }
      case API_GET_RIDERS_IN_RACE_SUCCESS: {
        const { response } = action.payload;

        return {
          ...state,
          ...getStateSuccess(response),
        };
      }
      case API_GET_RIDERS_IN_RACE_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
          current: state?.current,
        };
      }
      default: {
        return state;
      }
    }
  },
  liveData: (state = {}, action) => {
    switch (action.type) {
      case API_GET_LIVE_DATA: {
        return {
          ...state,
          ...getStateInProgress(),
        };
      }
      case API_CLEAR_LIVE_DATA: {
        return {
          ...state,
          current: [],
        };
      }
      case API_POLLING_SUCCESS: {
        const { response } = action.payload;
        const temp = {};
        // eslint-disable-next-line consistent-return
        response.forEach((res) => {
          const {
            UCIID,
            EventTimeStamp,
            RiderCadency,
            RiderPower,
            RiderSpeed,
            RiderHeartrate,
            TimeSpentInRedZone,
          } = res;
          let curObj = {
            data: [],
            tracking: [],
          };
          if (state.current && state.current[UCIID]) {
            curObj = state.current[UCIID];
          }
          if (
            UCIID === undefined
            || (curObj
              && (curObj.data.find((d) => d.EventTimeStamp === EventTimeStamp)
                || curObj.tracking.find(
                  (t) => t.EventTimeStamp === EventTimeStamp,
                )))
          ) {
            return {
              ...state,
            };
          }

          curObj.data.push({
            UCIID,
            EventTimeStamp,
            RiderPower,
            RiderCadency,
            RiderHeartrate,
            TimeSpentInRedZone,
          });
          curObj.tracking.push({
            UCIID,
            EventTimeStamp,
            Speed: RiderSpeed,
          });

          temp[UCIID] = curObj;
        });

        return {
          ...state,
          current: {
            ...state.current,
            ...temp,
          },
        };
      }
      case API_GET_LIVE_DATA_SUCCESS: {
        const { response } = action.payload;

        const resultState = {};

        response.forEach((res) => {
          let uciid;
          const {
            tracking,
            data,
          } = res;
          if (data.length > 0) {
            uciid = data[0].UCIID;
          } else if (tracking.length > 0) {
            uciid = tracking[0].UCIID;
          }

          if (!uciid) return;

          resultState[uciid] = {};
          resultState[uciid].data = data.sort(
            (a, b) => b.EventTimeStamp - a.EventTimeStamp,
          );
          resultState[uciid].tracking = tracking.sort(
            (a, b) => b.EventTimeStamp - a.EventTimeStamp,
          );
        });

        return {
          ...state,
          ...getStateSuccess(resultState),
        };
      }
      case API_GET_LIVE_DATA_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
          current: state?.current,
        };
      }
      default: {
        return state;
      }
    }
  },
  liveRiderData: (state = {}, action) => {
    switch (action.type) {
      case API_GET_LIVE_RIDER_DATA: {
        return {
          ...state,
          ...getStateInProgress(),
        };
      }
      case API_CLEAR_LIVE_DATA: {
        return {
          ...state,
          current: [],
        };
      }
      case API_LIVE_RIDER_DATA_POLLING_SUCCESS: {
        const { response } = action.payload;

        return {
          ...state,
          current: response,
        };
      }
      case API_GET_LIVE_RIDER_DATA_SUCCESS: {
        const { response } = action.payload;

        return {
          ...state,
          ...getStateSuccess(response),
        };
      }
      case API_GET_LIVE_RIDER_DATA_ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          ...getStateError(error),
          current: state?.current,
        };
      }
      default: {
        return state;
      }
    }
  },
  latestTimeStamp: (state = {}, action) => {
    switch (action.type) {
      case API_SET_LASTEST_TIMESTAMP: {
        const { latestTimeStamp } = action.payload;
        return {
          ...state,
          ...getStateSuccess(new Date(latestTimeStamp)),
        };
      }
      case API_CLEAR_LIVE_DATA: {
        return {
          ...state,
          current: undefined,
        };
      }
      default: {
        return state;
      }
    }
  },
  riderStats: (state = [], action) => {
    switch (action.type) {
      case API_GET_RIDER_STATS: {
        const { uciId } = action.payload;
        return {
          ...state,
          [{ uciId }]: getStateInProgress(),
        };
      }
      case API_GET_RIDER_STATS_SUCCESS: {
        const {
          uciId,
          response,
        } = action.payload;
        return {
          ...state,
          [{ uciId }]: getStateSuccess(response),
        };
      }
      case API_GET_RIDER_STATS_ERROR: {
        const {
          uciId,
          error,
        } = action.payload;
        return {
          ...state,
          [{ uciId }]: getStateError(error),
        };
      }
      default: {
        return state;
      }
    }
  },
  comparedRiderStats: (state = [], action) => {
    switch (action.type) {
      case API_GET_COMPARED_RIDER_STATS: {
        const { uciId } = action.payload;
        return {
          ...state,
          [{ uciId }]: getStateInProgress(),
        };
      }
      case API_GET_COMPARED_RIDER_STATS_SUCCESS: {
        const {
          uciId,
          response,
        } = action.payload;
        return {
          ...state,
          [{ uciId }]: getStateSuccess(response),
        };
      }
      case API_GET_COMPARED_RIDER_STATS_ERROR: {
        const {
          uciId,
          error,
        } = action.payload;
        return {
          ...state,
          [{ uciId }]: getStateError(error),
        };
      }
      default: {
        return state;
      }
    }
  },
});
