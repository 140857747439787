import gql from 'graphql-tag';

export default gql(`
query($uciId: String!, $nextToken: String) {
  getRiderResultsInRaces(uciId: $uciId, nextToken: $nextToken) {
    nextToken
    items {
      stats {
        AvgRaceRiderCadency
        AvgRaceRiderPower
        AvgRaceRiderSpeed
        Bib
        EventID
        EventTimeStamp
        IsInHeartrateGreenZone
        IsInHeartrateOrangeZone
        IsInHeartrateRedZone
        LeagueCat
        MaxRaceCadency
        MaxRacePower
        MaxRaceRiderCadency
        MaxRaceRiderPower
        MaxRaceRiderSpeed
        MaxRaceSpeed
        Message
        RaceID
        RiderCadency
        RiderPower
        RiderSpeed
        SeasonID
        TimeSpentInGreenZone
        TimeSpentInOrangeZone
        TimeSpentInRedZone
        UCIID
        RiderHeartrate
        AvgRaceRiderHeartrate
        MaxRaceRiderHeartrate
        RiderRank
        MaxRaceHeartrate
      }
      TotalHeats
      TotalRounds
    }  
  }
}`);
