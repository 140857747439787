/**
 * API_GET_RACES
 */
export const API_GET_RACES = 'API.GET_RACES';
export const API_GET_RACES_SUCCESS = 'API.GET_RACES_SUCCESS';
export const API_GET_RACES_ERROR = 'API.GET_RACES_ERROR';
/**
 * API_GET_RACE_RESULTS
 */
export const API_GET_RACE_RESULTS = 'API.GET_RACE_RESULTS';
export const API_GET_RACE_RESULTS_SUCCESS = 'API.GET_RACE_RESULTS_SUCCESS';
export const API_GET_RACE_RESULTS_ERROR = 'API.GET_RACE_RESULTS_ERROR';
/**
 * API_GET_RACE_RESULTS_STATS
 */
export const API_GET_RACE_RESULTS_STATS = 'API.GET_RACE_RESULTS_STATS';
export const API_GET_RACE_RESULTS_STATS_SUCCESS = 'API.GET_RACE_RESULTS_STATS_SUCCESS';
export const API_GET_RACE_RESULTS_STATS_ERROR = 'API.GET_RACE_RESULTS_STATS_ERROR';
/**
 * API_GET_RIDER_SEASON_STATS
 */
export const API_GET_RIDER_STATS = 'API.API_GET_RIDER_STATS';
export const API_GET_RIDER_STATS_ERROR = 'API.API_GET_RIDER_STATS_ERROR';
export const API_GET_RIDER_STATS_SUCCESS = 'API.API_GET_RIDER_STATS_SUCCESS';
/**
 * API_GET_RIDER_SEASON_STATS
 */
export const API_GET_COMPARED_RIDER_STATS = 'API.API_GET_COMPARED_RIDER_STATS';
export const API_GET_COMPARED_RIDER_STATS_ERROR = 'API.API_GET_COMPARED_RIDER_STATS_ERROR';
export const API_GET_COMPARED_RIDER_STATS_SUCCESS = 'API.API_GET_COMPARED_RIDER_STATS_SUCCESS';
/**
 * API_GET_LIVE_RACE
 */
export const API_GET_LIVE_RACE = 'API.GET_LIVE_RACE';
export const API_GET_LIVE_RACE_SUCCESS = 'API.GET_LIVE_RACE_SUCCESS';
export const API_GET_LIVE_RACE_ERROR = 'API.GET_LIVE_RACE_ERROR';
export const API_STOP_POLLING_LIVE_RACE = 'API.STOP_POLLING_LIVE_RACE';
/**
 * API_GET_RIDERS_IN_RACE
 */
export const API_GET_RIDERS_IN_RACE = 'API.GET_RIDERS_IN_RACE';
export const API_GET_RIDERS_IN_RACE_SUCCESS = 'API.GET_RIDERS_IN_RACE_SUCCESS';
export const API_GET_RIDERS_IN_RACE_ERROR = 'API.GET_RIDERS_IN_RACE_ERROR';
/**
 * API_GET_RIDERS_LIST
 */
export const API_GET_RIDERS_LIST = 'API.API_GET_RIDERS_LIST';
export const API_GET_RIDERS_LIST_SUCCESS = 'API.API_GET_RIDERS_LIST_SUCCESS';
export const API_GET_RIDERS_LIST_ERROR = 'API.API_GET_RIDERS_LIST_ERROR';
/**
 * API_GET_RIDER_DETAILS
 */
export const API_GET_RIDER_DETAILS = 'API.GET_RIDER_DETAILS';
export const API_GET_RIDER_DETAILS_SUCCESS = 'API.GET_RIDER_DETAILS_SUCCESS';
export const API_GET_RIDER_DETAILS_ERROR = 'API.GET_RIDER_DETAILS_ERROR';
/**
 * API_GET_COMPARED_RIDER_DETAILS
 */
export const API_GET_COMPARED_RIDER_DETAILS = 'API.GET_COMPARED_RIDER_DETAILS';
export const API_GET_COMPARED_RIDER_DETAILS_SUCCESS = 'API.GET_COMPARED_RIDER_DETAILS_SUCCESS';
export const API_GET_COMPARED_RIDER_DETAILS_ERROR = 'API.GET_COMPARED_RIDER_DETAILS_ERROR';
/**
 * API_TOGGLE_RIDER_CONFIDENCE
 */
export const API_TOGGLE_RIDER_CONFIDENCE = 'API.TOGGLE_RIDER_CONFIDENCE';
export const API_TOGGLE_RIDER_CONFIDENCE_SUCCESS = 'API.TOGGLE_RIDER_CONFIDENCE_SUCCESS';
export const API_TOGGLE_RIDER_CONFIDENCE_ERROR = 'API.TOGGLE_RIDER_CONFIDENCE_ERROR';
/**
 * API_GET_EVENTS
 */
export const API_GET_EVENTS = 'API.GET_EVENTS';
export const API_GET_EVENTS_SUCCESS = 'API.GET_EVENTS_SUCCESS';
export const API_GET_EVENTS_ERROR = 'API.GET_EVENTS_ERROR';
/**
 * API_GET_LIVE_DATA
 */
export const API_GET_LIVE_DATA = 'API.GET_LIVE_DATA';
export const API_GET_LIVE_DATA_SUCCESS = 'API.GET_LIVE_DATA_SUCCESS';
export const API_GET_LIVE_DATA_ERROR = 'API.GET_LIVE_DATA_ERROR';
/**
 * API_GET_LIVE_RIDER_DATA
 */
export const API_GET_LIVE_RIDER_DATA = 'API.GET_LIVE_RIDER_DATA';
export const API_GET_LIVE_RIDER_DATA_SUCCESS = 'API.GET_LIVE_RIDER_DATA_SUCCESS';
export const API_GET_LIVE_RIDER_DATA_ERROR = 'API.GET_LIVE_RIDER_DATA_ERROR';
export const API_CLEAR_LIVE_DATA = 'API.CLEAR_LIVE_DATA';

export const API_START_POLLING = 'API.START_POLLING';
export const API_POLLING_ERROR = 'API.ERROR_POLLING';
export const API_POLLING_SUCCESS = 'API.POLLING_SUCCESS';
export const API_STOP_POLLING = 'API.STOP_POLLING';

export const API_START_LIVE_RIDER_DATA_POLLING = 'API.START_LIVE_RIDER_DATA_POLLING';
export const API_LIVE_RIDER_DATA_POLLING_ERROR = 'API.LIVE_RIDER_DATA_ERROR_POLLING';
export const API_LIVE_RIDER_DATA_POLLING_SUCCESS = 'API.LIVE_RIDER_DATA_POLLING_SUCCESS';
export const API_STOP_LIVE_RIDER_DATA_POLLING = 'API.STOP_LIVE_RIDER_DATA_POLLING';
/**
 * API_LIST_COUNTRIES
 */
export const API_LIST_COUNTRIES = 'API.LIST_COUNTRIES';
export const API_LIST_COUNTRIES_SUCCESS = 'API.LIST_COUNTRIES_SUCCESS';
export const API_LIST_COUNTRIES_ERROR = 'API.LIST_COUNTRIES_ERROR';
/**
 * GET_ELIMINATED_RIDERS
 */
export const API_GET_ELIMINATED_RIDERS = 'API.GET_ELIMINATED_RIDERS';
export const API_GET_ELIMINATED_RIDERS_SUCCESS = 'API.GET_ELIMINATED_RIDERS_SUCCESS';
export const API_GET_ELIMINATED_RIDERS_ERROR = 'API.GET_ELIMINATED_RIDERS_ERROR';
/**
 * GET_STANDINGS
 */
export const API_GET_STANDINGS = 'API.GET_STANDINGS';
export const API_GET_STANDINGS_SUCCESS = 'API.GET_STANDINGS_SUCCESS';
export const API_GET_STANDINGS_ERROR = 'API.GET_STANDINGS_ERROR';
/**
 * API_GET_UI_SETTINGS
 */
export const API_GET_UI_SETTINGS = 'API.API_GET_UI_SETTINGS';
export const API_GET_UI_SETTINGS_SUCCESS = 'API.API_GET_UI_SETTINGS_SUCCESS';
export const API_GET_UI_SETTINGS_ERROR = 'API.API_GET_UI_SETTINGS_ERROR';
/**
 * API_SET_UI_SETTINGS
 */
export const API_SET_UI_SETTINGS = 'API.API_SET_UI_SETTINGS';
export const API_SET_UI_SETTINGS_SUCCESS = 'API.API_SET_UI_SETTINGS_SUCCESS';
export const API_SET_UI_SETTINGS_ERROR = 'API.API_SET_UI_SETTINGS_ERROR';
/**
 * API_GET_SEASONS
 */
export const API_GET_SEASONS = 'API.GET_SEASONS';
export const API_GET_SEASONS_SUCCESS = 'API.GET_SEASONS_SUCCESS';
export const API_GET_SEASONS_ERROR = 'API.GET_SEASONS_ERROR';

export const API_POLLING_INTERVAL = 1000;

export const API_SET_LASTEST_TIMESTAMP = 'API.SET_LASTEST_TIMESTAMP';
