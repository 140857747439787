import React, { useState } from 'react';
import placeholder from '../images/placeholder.png';
import './Layout.scss';

export default function ImageWithFallback(props) {
  const [imageSrc, setImageSrc] = useState(props.src || placeholder);

  return (
    <img
      {...props}
      onError={() => {
        if (imageSrc !== placeholder) {
          setImageSrc(placeholder);
        }
      }}
      src={imageSrc}
      alt="Fallback"
    />
  );
}
