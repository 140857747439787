import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import Layout from '../../components/Layout';

export function CamSettingsPage() {
  const pageTitle = 'Cam settings';
  return (
    <Layout title={pageTitle}>
      <div className="mt-12 ">
        <div className="row" />
      </div>
      <ToastContainer position="bottom-right" />
    </Layout>
  );
}
