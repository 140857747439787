import gql from 'graphql-tag';

export default gql(`
mutation($uciId: String!, $confidence: ConfidenceValueInput) {
  setRiderConfidence(uciId: $uciId, confidence: $confidence) {
    UCIID
    confidence {
      speed
      cadence
      heartrate
      power
    }
  }
}`);
