import React, { useEffect, useState, useMemo } from 'react';

import { NewGrid } from '../../components/Grid';
import { fuzzySort } from '../../utils/utils';
import { RiderRacesStats } from './RiderRacesStats';

export function RiderEventsStats({ riderStats }) {
  const [eventData, setEventData] = useState();

  function formatEventStats() {
    const eventStats = riderStats?.map((item) => {
      const stats = {
        raceType: item.raceType,
        starts: item.eventStarts,
        wins: item.eventWins,
        winRate: item.eventWinRate?.toFixed(2),
        top3: item.eventInTop3,
        top5: item.eventInTop5,
        top6: item.eventInTop6,
        bottom5: item.eventInBottom5,
        aggregatedPoints: item.aggregatedPoints,
        pointsRate: Number(item?.pointsRate).toFixed(2),
      };
      return stats;
    });
    if (riderStats?.find((item) => item.raceType === 'Keirin')
      && riderStats?.find((item) => item.raceType === 'Sprint')) {
      const keirinStats = riderStats?.find((item) => item.raceType === 'Keirin');
      const sprintStats = riderStats?.find((item) => item.raceType === 'Sprint');
      if (keirinStats && sprintStats) {
        const sumStarts = Number(keirinStats?.eventStarts) + Number(sprintStats?.eventStarts);
        const sumWins = Number(keirinStats?.eventWins) + Number(sprintStats?.eventWins);
        const cumulativeWinRate = (sumWins / sumStarts) * 100;

        eventStats.push({
          raceType: 'Round (Keirin + Sprint)',
          starts: sumStarts,
          wins: sumWins,
          winRate: cumulativeWinRate.toFixed(2),
          top3: Number(keirinStats?.eventInTop3) + Number(sprintStats?.eventInTop3),
          top5: Number(keirinStats?.eventInTop5) + Number(sprintStats?.eventInTop5),
          top6: Number(keirinStats?.eventInTop6) + Number(sprintStats?.eventInTop6),
          bottom5: Number(keirinStats?.eventInBottom5) + Number(sprintStats?.eventInBottom5),
          aggregatedPoints:
            Number(keirinStats?.aggregatedPoints) + Number(sprintStats?.aggregatedPoints),
          pointsRate:
            ((Number(keirinStats?.pointsRate) + Number(sprintStats?.pointsRate)) * 0.5).toFixed(2) || '',
        });
      }
    }
    if (riderStats?.find((item) => item.raceType === 'Scratch')
      && riderStats?.find((item) => item.raceType === 'Elimination')) {
      const r1Stats = riderStats?.find((item) => item.raceType === 'Scratch');
      const r2Stats = riderStats?.find((item) => item.raceType === 'Elimination');
      if (r1Stats && r2Stats) {
        const sumStarts = Number(r1Stats?.eventStarts) + Number(r2Stats?.eventStarts);
        const sumWins = Number(r1Stats?.eventWins) + Number(r2Stats?.eventWins);
        const cumulativeWinRate = (sumWins / sumStarts) * 100;

        eventStats.push({
          raceType: 'Round (Scratch + Elimination)',
          starts: Number(r1Stats?.eventStarts) + Number(r2Stats?.eventStarts),
          wins: Number(r1Stats?.eventWins) + Number(r2Stats?.eventWins),
          winRate: cumulativeWinRate.toFixed(2),
          top3: Number(r1Stats?.eventInTop3) + Number(r2Stats?.eventInTop3),
          top5: Number(r1Stats?.eventInTop5) + Number(r2Stats?.eventInTop5),
          top6: Number(r1Stats?.eventInTop6) + Number(r2Stats?.eventInTop6),
          bottom5: Number(r1Stats?.eventInBottom5) + Number(r2Stats?.eventInBottom5),
          aggregatedPoints: Number(r1Stats?.aggregatedPoints) + Number(r2Stats?.aggregatedPoints),
          pointsRate:
            ((Number(r1Stats?.pointsRate) + Number(r2Stats?.pointsRate)) * 0.5).toFixed(2) || '',
        });
      }
    }
    return eventStats;
  }

  useEffect(() => {
    setEventData(formatEventStats());
  }, [riderStats]);

  const EventColumns = [
    {
      accessorFn: (row) => `${row.raceType}`,
      id: 'raceType',
      header: 'Event Type',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.starts}`,
      id: 'starts',
      header: '# of Starts',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.wins}`,
      id: 'wins',
      header: '# of Wins',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.winRate}`,
      id: 'winRate',
      header: 'Win Rate',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.top3}`,
      id: 'top3',
      header: 'In Top 3',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.top5}`,
      id: 'top5',
      header: 'In Top 5',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.top6}`,
      id: 'top6',
      header: 'In Top 6',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.bottom5}`,
      id: 'bottom5',
      header: 'In Bottom5',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.aggregatedPoints}`,
      id: 'aggregatedPoints',
      header: 'Aggregated Points',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.pointsRate}`,
      id: 'pointsRate',
      header: 'Points Rate',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
  ];

  const memoizedContent = useMemo(() => (
    <>
      <div>
        <NewGrid
          data={eventData}
          columns={EventColumns}
        />
      </div>
      { riderStats?.map((item, index) => (
        <RiderRacesStats
          key={`RiderRacesStats_${Number(index)}`}
          raceClassification={item.classification}
        />
      ))}
    </>
  ), [eventData, riderStats]);

  return riderStats ? (
    <>
      <h1 className="mt-8 mb-4 font-semibold" style={{ fontSize: '1.5em' }}>Cummulative Statistics</h1>
      {memoizedContent}
    </>
  ) : null;
}
