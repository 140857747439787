import gql from 'graphql-tag';

export default gql(`
query($raceId: String!, $uciId: String, $nextToken: String) {
  getCurrentRiderData(raceId: $raceId, uciId: $uciId, nextToken: $nextToken) {
    AvgRaceRiderCadency
    AvgRaceRiderPower
    AvgRaceRiderSpeed
    Bib
    EventID
    EventTimeStamp
    IsInHeartrateGreenZone
    IsInHeartrateOrangeZone
    IsInHeartrateRedZone
    LeagueCat
    MaxRaceCadency
    MaxRacePower
    MaxRaceRiderCadency
    MaxRaceRiderPower
    MaxRaceRiderSpeed
    MaxRaceSpeed
    Message
    RaceID
    RiderCadency
    RiderPower
    RiderSpeed
    SeasonID
    TimeSpentInGreenZone
    TimeSpentInOrangeZone
    TimeSpentInRedZone
    UCIID
    RiderHeartrate
    AvgRaceRiderHeartrate
    MaxRaceRiderHeartrate
    RiderRank
    MaxRaceHeartrate
    RiderPowerMeterAverage
    RiderHeartBeatAverage
  }
}`);
