import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';

import AppRouter from './App.router';
import Header from './components/Header/Header';
import { apiActions } from './store/actions/api.actions';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(apiActions.listCountries());
  }, [dispatch]);

  useEffect(() => {
    dispatch(apiActions.getUISettings());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <div className="App">
        <Header />

        <AppRouter />
      </div>
    </BrowserRouter>
  );
}

export default withAuthenticator(App);
