import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '../../components/Layout';
import { useHookParam } from '../../hooks/useRouteParam';
import { apiActions } from '../../store/actions/api.actions';
import { RaceResult } from './RaceResult';

export function RaceResults() {
  const dispatch = useDispatch();
  const {
    raceId,
    eventId,
  } = useParams();

  const raceOperation = useSelector((state) => state.api.races);
  const eventsOperation = useSelector((state) => state.api.events);

  const { match: race } = useHookParam('raceId', raceOperation);
  const { match: event } = useHookParam('eventId', eventsOperation);

  const pageTitle = useMemo(() => {
    if (!race) {
      return undefined;
    }
    return `Results: ${race?.RaceName} - ${race?.League}`;
  }, [race]);

  const pageTitleBold = useMemo(() => {
    if (!event) {
      return undefined;
    }
    return ` ${event?.wordpress?.title}`;
  }, [event]);

  useEffect(() => {
    if (!eventsOperation?.current?.length) {
      dispatch(apiActions.getEvents());
    }
  }, [eventsOperation?.current?.length]);

  useEffect(() => {
    if (!raceOperation?.current?.length) {
      dispatch(apiActions.getRaces({ eventId }));
    }
  }, [eventId, raceOperation?.current?.length]);

  return (
    <Layout
      title={pageTitle}
      titleBold={pageTitleBold}
      subtitle={race?.StartTime}
      operation={raceOperation}
    >
      <RaceResult results={race} raceId={raceId} />
    </Layout>
  );
}
