import gql from 'graphql-tag';

export default gql(`
query($uciId: String!, $seasonId: String!) {
  getRider(uciId: $uciId, seasonId: $seasonId) {
    FirstName
    LastName
    BirthDate
    UCIID
    Gender
    TrainingLocation
    LeagueCat
    Nationality
    SeasonTitle
    HeightCm
    WeightKg
    RestHrBpm
    MaxHrBpm
    Flying200
    GearingForFlying200
    PowerPeakW
    Power5sW
    Power15sW
    Power30sW
    Power60sW
    Power120sW
    Power180sW
    Power300sW
    Power600sW
    Power1200sW
    Power1800sW
    Power3600sW
    Bib
    wordpress {
      id
      uciID
      firstName
      lastName
      countryCode
      countryName
      age
      birthdate
      height
      weight
      biography
      portraitImageURI
      isBlueJersey
      isRiderToWatch
      isGuest
      isFoundingPartner
      facebookURL
      instagramURL
      twitterURL
      websiteURL
      youtubeURL
      portraitImageURIMedium
      portraitImageURISmall
      palmares
    }
  }
}`);

// wordpress {
//   id
//   uciID
//   firstName
//   lastName
//   countryCode
//   countryName
//   age
//   birthdate
//   height
//   weight
//   biography
//   portraitImageURI
//   portraitImageURISmall
// }
