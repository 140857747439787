import gql from 'graphql-tag';

export default gql(`
query($raceId: String!) {

  getRidersInRace(raceId: $raceId) {
    nextToken
    items {
      UCIID
      PredictedRank
      FirstName
      LastName
    }
  }
}`);
