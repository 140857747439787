import gql from 'graphql-tag';

export default gql(`
query($uciId: String!, $nextToken: String) {
  getRiderStartLists(uciId: $uciId, nextToken: $nextToken) {
    items {
      Message
      SeasonID
      EventID
      RaceID
      Gender
      RaceType
      League
      Heat
      TotalHeats
      Round
      TotalRounds
      PredictedRank
      RaceName
      RaceLaps
      Distance
      TimeStamp
      Bib
      UCIID
      FirstName
      LastName
      wordpress {
        id
        uciID
        firstName
        lastName
        countryCode
        countryName
        age
        birthdate
        height
        weight
        biography
        portraitImageURI
        isBlueJersey
        isRiderToWatch
        isGuest
        isFoundingPartner
        facebookURL
        instagramURL
        twitterURL
        websiteURL
        youtubeURL
        portraitImageURIMedium
        portraitImageURISmall
        palmares
      }
      
    }
    nextToken    
  }
}`);
