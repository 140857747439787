import { useHistory } from 'react-router-dom';
import { Button } from '@awsui/components-react';
import React, { useEffect, useState } from 'react';

import { fuzzySort } from '../../utils/utils';
import { isAdminUser } from '../../services/user';
import { ConfidenceColumn } from './ConfidenceColumn';
import CountryFlag from '../../components/CountryFlag';
import ImageWithFallback from '../../components/ImageWithFallback';

export const useColumns = () => {
  const history = useHistory();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    (async () => {
      setIsAdmin(await isAdminUser());
    })();
  }, []);

  const renderBib = ({ row }) => (
    <>
      {row.original?.Bib}
      <div className="flex flex-row justify-between pr-4">
        <ImageWithFallback
          src={row.original?.wordpress?.portraitImageURISmall}
          className="rounded-full w-8 h-8 mr-2"
        />
      </div>
    </>
  );

  const renderActions = ({ row }) => (
    <div>
      <Button
        className="h-8 mr-4 border-blue text-blue border-2 border-opacity-50"
        onClick={() => {
          history.push(`/rider/${encodeURIComponent(row.original?.UCIID)}`);
        }}
      >
        Rider Info
      </Button>
    </div>
  );

  const columns = React.useMemo(() => [
    {
      accessorFn: (row) => `${row.Bib}`,
      id: 'Bib',
      header: 'Bib',
      cell: renderBib,
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.FirstName} ${row.LastName}`,
      id: 'Name',
      header: 'Rider Name',
      cell: (info) => <p className="h6">{info.getValue()}</p>,
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.UCIID}`,
      id: 'UCIID',
      header: 'UCI ID',
      cell: (info) => <p className="h6">{info.getValue()}</p>,
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.Confidence}`,
      id: 'Confidence',
      header: 'Confidence',
      cell: (info) => (
        <ConfidenceColumn confidence={info.getValue()} />
      ),
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.Nationality}`,
      id: 'Nationality',
      header: 'Nationality',
      cell: (info) => (
        <CountryFlag countryName={info.getValue()} />
      ),
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.LeagueCat}`,
      id: 'LeagueCat',
      header: 'Race Category',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      header: 'Actions',
      id: 'Actions',
      show: true,
      size: 6,
      cell: renderActions,
    },
  ], []);

  const filteredColumns = columns.filter(
    (item) => !item.isAdmin || item.isAdmin === isAdmin,
  );

  return filteredColumns;
};
