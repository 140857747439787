/* eslint no-param-reassign: 0 */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/uci-logo.png';

import { useColumns } from './useColumns';
import Layout from '../../components/Layout';
import { fuzzySort } from '../../utils/utils';
import { NewGrid } from '../../components/Grid';
import { useHookParam } from '../../hooks/useRouteParam';
import { apiActions } from '../../store/actions/api.actions';

function getEliminatedRidersList(eliminatedRidersData) {
  const columns = [
    {
      accessorFn: (row) => `${row.Bib}`,
      id: 'Bib',
      header: 'Bib',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.UCIID}`,
      id: 'UCIID',
      header: 'UCI ID',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.FirstName} ${row.LastName}`,
      id: 'Name',
      header: 'Name',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.Team}`,
      id: 'Team',
      header: 'Team',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
  ];

  return (
    <>
      <h3 className="text-gray-dark text-xl font-bold">Eliminated Riders</h3>
      <NewGrid data={eliminatedRidersData || []} columns={columns} />
    </>
  );
}

export function LiveRace() {
  const dispatch = useDispatch();
  const {
    raceId,
    eventId,
  } = useParams();

  useEffect(() => {
    if (eventId) {
      dispatch(apiActions.stopLiveRiderDataPolling());
      dispatch(
        apiActions.getLiveRace({
          eventId,
          poll: false,
        }),
      );
    }
  }, [eventId]);

  const raceOperation = useSelector((state) => state.api.races);
  const eventsOperation = useSelector((state) => state.api.events);

  const riderListOperation = useSelector((state) => state.api.riders);
  const liveRiderData = useSelector((state) => state.api.liveRiderData.current);
  const eliminatedRidersData = useSelector(
    (state) => state.api.eliminatedRiders.current,
  );
  const currentRaceOperation = useSelector((state) => state.api.curRace);

  const [riders, setRiders] = useState({});

  useEffect(() => {
    if (!riderListOperation?.current?.items?.length) {
      dispatch(apiActions.getRidersList(eventId.substring(0, 4)));
    } else if (riderListOperation?.current?.items?.length) {
      const tempriders = {};
      riderListOperation?.current?.items.forEach((rider) => {
        tempriders[rider.UCIID] = rider;
      });
      setRiders(tempriders);
    }
  }, [riderListOperation?.current?.items?.length, setRiders]);

  const { match: race } = useHookParam('raceId', raceOperation);
  const { match: event } = useHookParam('eventId', eventsOperation);

  const pageTitle = useMemo(() => {
    if (!race) {
      return undefined;
    }
    return `Live Results: ${race?.RaceName} - ${race?.League} - ${raceId}`;
  }, [race]);

  const pageTitleBold = useMemo(() => {
    if (!event) {
      return undefined;
    }
    return ` ${event?.wordpress?.title}`;
  }, [event]);

  useEffect(() => {
    if (!eventsOperation?.current?.length) {
      dispatch(apiActions.getEvents());
    }
  }, [eventsOperation?.current]);

  useEffect(() => {
    if (
      !eliminatedRidersData?.current?.length
      && raceId
      && race?.RaceType === 'Elimination'
    ) {
      dispatch(apiActions.getEliminatedRiders(raceId));
    }
  }, [eliminatedRidersData?.current, raceId, race]);

  useEffect(() => {
    if (!raceOperation?.current?.length) {
      dispatch(apiActions.getRaces({ eventId }));
    }
  }, [raceOperation?.current, eventId]);

  useEffect(() => {
    if (raceId?.length) {
      dispatch(apiActions.getRidersInRace({ raceId }));
    }
  }, [dispatch, raceId]);

  useEffect(() => {
    if (raceId !== null) {
      dispatch(apiActions.clearLiveData());
      dispatch(
        apiActions.getLiveRiderData({
          raceId,
          polling: false,
        }),
      );
    }
  }, [raceId]);

  const riderData = useMemo(() => {
    // eslint-disable-next-line max-len
    let currentRiders = liveRiderData?.data?.getCurrentRiderData.map((item) => _.omitBy(item, _.isNil));

    currentRiders = _.groupBy(currentRiders, 'UCIID');

    return (
      currentRaceOperation?.current?.map((keyRiderLiveData) => ({
        data: currentRiders[keyRiderLiveData.UCIID]?.[0] || {},
        profile: riders[keyRiderLiveData.UCIID],
      })) || []
    );
  }, [liveRiderData, currentRaceOperation?.current]);

  const columns = useColumns();

  const RiderDataGrid = useCallback(({ data }) => {
    const filteredData = data.map((item) => {
      const confidence = item?.profile?.Confidence || {};

      const {
        cadence = true,
        power = true,
        heartrate = true,
        speed = true,
      } = confidence;

      if (!power) {
        item.data.RiderPower = '';
        item.data.MaxRaceRiderPower = '';
      }

      if (!cadence) {
        item.data.RiderCadency = '';
        item.data.MaxRaceRiderCadency = '';
      }

      if (!heartrate) {
        item.data.RiderHeartrate = '';
        item.data.MaxRaceRiderHeartrate = '';
        item.data.Hrv = '';
      }

      if (!speed) {
        item.data.RiderSpeed = '';
        item.data.MaxRaceRiderSpeed = '';
      }

      return item;
    });
    return <NewGrid data={filteredData || []} columns={columns} displayAllRows />;
  });

  return (
    <Layout
      title={pageTitle}
      titleBold={pageTitleBold}
      subtitle={race?.StartTime}
      operation={raceOperation}
    >
      {!riderListOperation?.status?.isLoading ? (
        <>
          <RiderDataGrid data={riderData || []} />

          {eliminatedRidersData?.items
            && getEliminatedRidersList(eliminatedRidersData.items)}
        </>
      ) : (
        <img
          alt="loader"
          className="position-absolute top-50 start-50 translate-middle blob z-20 "
          src={logo}
        />
      )}

      {/* <RaceResult results={race} raceId={raceId} /> */}
    </Layout>
  );
}
