import gql from 'graphql-tag';

export default gql(`
  query MyQuery ($seasonId: String, $uciId: String!){
    getRiderStats(seasonId: $seasonId, uciId: $uciId) {
        eventInTop3
        eventInTop5
        eventInTop6
        eventInBottom5
        aggregatedPoints
        pointsRate
        eventStarts
        eventWinRate
        eventWins
        raceType
        classification {
          EventID
          FirstName
          LastName
          League
          Points
          RaceType
          Rank
          SeasonID
          State
          UCIID
          raceStats {
            inBottom5
            inTop3
            inTop5
            inTop6
            starts
            winRate
            wins
          }
          races {
            attendeesNo
            raceId
            rank
            pointsRate
          }
        }
    }
  }
`);
