import gql from 'graphql-tag';

export default gql(`query MyQuery($raceId: String!) {
  getEliminatedRiders(raceId: $raceId) {
    Bib
    DynamoIngestTime
    EliminatedRaceName
    EventID
    FirstName
    KinesisAnalyticsIngestTime
    LastName
    Message
    NOC
    RaceID
    SeasonID
    ServerTimeStamp
    ShortTVName
    Team
    UCIID
  }
}
`);
