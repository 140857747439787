/* eslint  max-len: 0 */

import { createAction } from '../utils/actions/action-creators.util';
import {
  API_CLEAR_LIVE_DATA,
  API_GET_ELIMINATED_RIDERS,
  API_GET_ELIMINATED_RIDERS_ERROR,
  API_GET_ELIMINATED_RIDERS_SUCCESS,
  API_GET_EVENTS,
  API_GET_EVENTS_ERROR,
  API_GET_EVENTS_SUCCESS,
  API_GET_LIVE_DATA,
  API_GET_LIVE_DATA_ERROR,
  API_GET_LIVE_DATA_SUCCESS,
  API_GET_LIVE_RACE,
  API_GET_LIVE_RACE_ERROR,
  API_GET_LIVE_RACE_SUCCESS,
  API_GET_LIVE_RIDER_DATA,
  API_GET_LIVE_RIDER_DATA_ERROR,
  API_GET_LIVE_RIDER_DATA_SUCCESS,
  API_GET_RACE_RESULTS,
  API_GET_RACE_RESULTS_ERROR,
  API_GET_RACE_RESULTS_STATS,
  API_GET_RACE_RESULTS_STATS_ERROR,
  API_GET_RACE_RESULTS_STATS_SUCCESS,
  API_GET_RIDER_STATS,
  API_GET_RIDER_STATS_ERROR,
  API_GET_RIDER_STATS_SUCCESS,
  API_GET_COMPARED_RIDER_STATS,
  API_GET_COMPARED_RIDER_STATS_ERROR,
  API_GET_COMPARED_RIDER_STATS_SUCCESS,
  API_GET_RACE_RESULTS_SUCCESS,
  API_GET_RACES,
  API_GET_RACES_ERROR,
  API_GET_RACES_SUCCESS,
  API_GET_RIDER_DETAILS,
  API_GET_RIDER_DETAILS_ERROR,
  API_GET_RIDER_DETAILS_SUCCESS,
  API_GET_COMPARED_RIDER_DETAILS,
  API_GET_COMPARED_RIDER_DETAILS_ERROR,
  API_GET_COMPARED_RIDER_DETAILS_SUCCESS,
  API_GET_RIDERS_IN_RACE,
  API_GET_RIDERS_IN_RACE_ERROR,
  API_GET_RIDERS_IN_RACE_SUCCESS,
  API_GET_RIDERS_LIST,
  API_GET_RIDERS_LIST_ERROR,
  API_GET_RIDERS_LIST_SUCCESS,
  API_GET_SEASONS,
  API_GET_SEASONS_ERROR,
  API_GET_SEASONS_SUCCESS,
  API_GET_STANDINGS,
  API_GET_STANDINGS_ERROR,
  API_GET_STANDINGS_SUCCESS,
  API_GET_UI_SETTINGS,
  API_GET_UI_SETTINGS_ERROR,
  API_GET_UI_SETTINGS_SUCCESS,
  API_LIST_COUNTRIES,
  API_LIST_COUNTRIES_ERROR,
  API_LIST_COUNTRIES_SUCCESS,
  API_LIVE_RIDER_DATA_POLLING_ERROR,
  API_LIVE_RIDER_DATA_POLLING_SUCCESS,
  API_POLLING_ERROR,
  API_POLLING_SUCCESS,
  API_SET_LASTEST_TIMESTAMP,
  API_SET_UI_SETTINGS,
  API_SET_UI_SETTINGS_ERROR,
  API_SET_UI_SETTINGS_SUCCESS,
  API_START_LIVE_RIDER_DATA_POLLING,
  API_START_POLLING,
  API_STOP_LIVE_RIDER_DATA_POLLING,
  API_STOP_POLLING,
  API_STOP_POLLING_LIVE_RACE,
  API_TOGGLE_RIDER_CONFIDENCE,
  API_TOGGLE_RIDER_CONFIDENCE_ERROR,
  API_TOGGLE_RIDER_CONFIDENCE_SUCCESS,
} from '../constants/api.constants';

export const apiActions = {
  /**
   * GET_EVENTS
   */
  getEvents: (request) => createAction(API_GET_EVENTS, request),
  getEventsError: (error) => createAction(API_GET_EVENTS_ERROR, { error }),
  getEventsSuccess: (response) => createAction(API_GET_EVENTS_SUCCESS, { response }),
  /**
   * GET_SEASONS
   */
  getSeasons: (request) => createAction(API_GET_SEASONS, request),
  getSeasonsError: (error) => createAction(API_GET_SEASONS_ERROR, { error }),
  getSeasonsSuccess: (response) => createAction(API_GET_SEASONS_SUCCESS, { response }),
  /**
   * GET_RACES
   */
  getRaces: (request) => createAction(API_GET_RACES, request),
  getRacesError: (error) => createAction(API_GET_RACES_ERROR, { error }),
  getRacesSuccess: (response) => createAction(API_GET_RACES_SUCCESS, { response }),
  /**
   * GET_LIVE_RACE
   */
  getLiveRace: (request) => createAction(API_GET_LIVE_RACE, request),
  getLiveRaceError: (error) => createAction(API_GET_LIVE_RACE_ERROR, { error }),
  getLiveRaceSuccess: (response) => createAction(API_GET_LIVE_RACE_SUCCESS, { response }),
  /**
   * GET_RIDERS_IN_RACE
   */
  // eslint-disable-next-line no-undef
  getRidersInRace: (request) => createAction(API_GET_RIDERS_IN_RACE, request),
  getRidersInRaceError: (error) => createAction(API_GET_RIDERS_IN_RACE_ERROR, { error }),
  getRidersInRaceSuccess: (response) => createAction(API_GET_RIDERS_IN_RACE_SUCCESS, { response }),
  /**
   * GET_RACE_RESULTS
   */
  // eslint-disable-next-line no-undef
  getRaceResults: (request) => createAction(API_GET_RACE_RESULTS, request),
  getRaceResultsError: (raceId, error) => createAction(API_GET_RACE_RESULTS_ERROR, {
    raceId,
    error,
  }),
  getRaceResultsSuccess: (raceId, response) => createAction(API_GET_RACE_RESULTS_SUCCESS, {
    raceId,
    response,
  }),
  /**
   * GET_RACE_RESULTS
   */
  // eslint-disable-next-line no-undef
  getRaceResultsStats: (request) => createAction(API_GET_RACE_RESULTS_STATS, request),
  getRaceResultsStatsError: (raceId, error) => createAction(API_GET_RACE_RESULTS_STATS_ERROR, {
    raceId,
    error,
  }),
  getRaceResultsStatsSuccess: (raceId, response) => createAction(API_GET_RACE_RESULTS_STATS_SUCCESS, {
    raceId,
    response,
  }),
  /**
   * GET_RIDER_STATS
   */
  // eslint-disable-next-line no-undef
  getRiderStats: (request) => createAction(API_GET_RIDER_STATS, request),
  getRiderStatsError: (uciId, error) => createAction(API_GET_RIDER_STATS_ERROR, {
    uciId,
    error,
  }),
  getRiderStatsSuccess: (uciId, response) => createAction(API_GET_RIDER_STATS_SUCCESS, {
    uciId,
    response,
  }),
  /**
   * GET_COMPARED_RIDER_STATS
   */
  // eslint-disable-next-line no-undef
  getComparedRiderStats: (request) => createAction(API_GET_COMPARED_RIDER_STATS, request),
  getComparedRiderStatsError: (uciId, error) => createAction(API_GET_COMPARED_RIDER_STATS_ERROR, {
    uciId,
    error,
  }),
  getComparedRiderStatsSuccess: (uciId, response) => createAction(API_GET_COMPARED_RIDER_STATS_SUCCESS, {
    uciId,
    response,
  }),
  /**
   * GET_RIDER_DETAILS
   */
  // eslint-disable-next-line no-undef
  getRiderDetails: (request) => createAction(API_GET_RIDER_DETAILS, request),
  getRiderDetailsError: (uciId, error) => createAction(API_GET_RIDER_DETAILS_ERROR, {
    uciId,
    error,
  }),
  getRiderDetailsSuccess: (uciId, rider, riderStartLists, riderResultsInRaces, confidence) => createAction(API_GET_RIDER_DETAILS_SUCCESS, {
    uciId,
    rider,
    riderStartLists,
    riderResultsInRaces,
    confidence,
  }),
  /**
   * GET_COMPARED_RIDER_DETAILS
   */
  // eslint-disable-next-line no-undef
  getComparedRiderDetails: (request) => createAction(API_GET_COMPARED_RIDER_DETAILS, request),
  getComparedRiderDetailsError: (uciId, error) => createAction(API_GET_COMPARED_RIDER_DETAILS_ERROR, {
    uciId,
    error,
  }),
  getComparedRiderDetailsSuccess: (uciId, rider, riderStartLists, riderResultsInRaces, confidence) => createAction(API_GET_COMPARED_RIDER_DETAILS_SUCCESS, {
    uciId,
    rider,
    riderStartLists,
    riderResultsInRaces,
    confidence,
  }),
  /**
   * TOGGLE_RIDER_CONFIDENCE
   */
  // eslint-disable-next-line no-undef
  toggleRiderConfidence: (request) => createAction(API_TOGGLE_RIDER_CONFIDENCE, request),
  toggleRiderConfidenceError: (uciId, error) => createAction(API_TOGGLE_RIDER_CONFIDENCE_ERROR, {
    uciId,
    error,
  }),
  toggleRiderConfidenceSuccess: (uciId, confidence) => createAction(API_TOGGLE_RIDER_CONFIDENCE_SUCCESS, {
    uciId,
    confidence,
  }),
  /**
   * GET_RIDERS_LIST
   */
  getRidersList: (seasonId) => createAction(API_GET_RIDERS_LIST, { seasonId }),
  getRidersListError: (error) => createAction(API_GET_RIDERS_LIST_ERROR, { error }),
  getRidersListSuccess: (response) => createAction(API_GET_RIDERS_LIST_SUCCESS, { response }),
  /**
   * LIST_COUNTRIES
   */
  listCountries: () => createAction(API_LIST_COUNTRIES, {}),
  listCountriesError: (error) => createAction(API_LIST_COUNTRIES_ERROR, { error }),
  listCountriesSuccess: (response) => createAction(API_LIST_COUNTRIES_SUCCESS, { response }),
  /**
   * GET_EVENTS
   */
  getLiveData: (request) => createAction(API_GET_LIVE_DATA, request),
  getLiveDataError: (error) => createAction(API_GET_LIVE_DATA_ERROR, { error }),
  getLiveDataSuccess: (response) => createAction(API_GET_LIVE_DATA_SUCCESS, { response }),

  /**
   * GET_API_GET_UI_SETTINGS
   */
  getEliminatedRiders: (request) => createAction(API_GET_ELIMINATED_RIDERS, request),
  getEliminatedRidersError: (error) => createAction(API_GET_ELIMINATED_RIDERS_ERROR, { error }),
  getEliminatedRidersSuccess: (response) => createAction(API_GET_ELIMINATED_RIDERS_SUCCESS, response),

  /**
   * GET_STANDINGS
   */
  getStandings: (request) => createAction(API_GET_STANDINGS, request),
  getStandingsError: (error) => createAction(API_GET_STANDINGS_ERROR, { error }),
  getStandingsSuccess: (response) => createAction(API_GET_STANDINGS_SUCCESS, response),

  /**
   * GET_API_GET_UI_SETTINGS
   */
  getUISettings: (request) => createAction(API_GET_UI_SETTINGS, request),
  getUISettingsError: (error) => createAction(API_GET_UI_SETTINGS_ERROR, { error }),
  getUISettingsSuccess: (response) => createAction(API_GET_UI_SETTINGS_SUCCESS, response),

  /**
   * SET_API_GET_UI_SETTINGS
   */
  setUISettings: (request) => createAction(API_SET_UI_SETTINGS, request),
  setUISettingsError: (error) => createAction(API_SET_UI_SETTINGS_ERROR, { error }),
  setUISettingsSuccess: (response) => createAction(API_SET_UI_SETTINGS_SUCCESS, response),

  /**
   * GET_LIVE_RIDER_DATA
   */
  getLiveRiderData: (request) => createAction(API_GET_LIVE_RIDER_DATA, request),
  getLiveRiderDataError: (error) => createAction(API_GET_LIVE_RIDER_DATA_ERROR, { error }),
  getLiveRiderDataSuccess: (response) => createAction(API_GET_LIVE_RIDER_DATA_SUCCESS, { response }),
  clearLiveData: () => createAction(API_CLEAR_LIVE_DATA),

  setPolling: () => createAction(API_START_POLLING),
  pollingError: (error) => createAction(API_POLLING_ERROR, { error }),
  pollingSuccess: (response) => createAction(API_POLLING_SUCCESS, { response }),
  stopPolling: () => createAction(API_STOP_POLLING),
  stopPollingLiveRace: () => createAction(API_STOP_POLLING_LIVE_RACE),

  setLiveRiderDataPolling: () => createAction(API_START_LIVE_RIDER_DATA_POLLING),
  liveRiderDataPollingError: (error) => createAction(API_LIVE_RIDER_DATA_POLLING_ERROR, { error }),
  liveRiderDataPollingSuccess: (response) => createAction(API_LIVE_RIDER_DATA_POLLING_SUCCESS, { response }),
  stopLiveRiderDataPolling: () => createAction(API_STOP_LIVE_RIDER_DATA_POLLING),

  setLatestTimeStamp: (latestTimeStamp) => createAction(API_SET_LASTEST_TIMESTAMP, { latestTimeStamp }),
};
