import moment from 'moment';
import { Tabs } from '@awsui/components-react';
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';
import Layout from '../../components/Layout';
import ImageWithFallback from '../../components/ImageWithFallback';

import { apiActions } from '../../store/actions/api.actions';

function EventTile({ event }) {
  return (
    <Link
      to={`/event/${event.eventId}`}
      key={event.eventId}
      className="no-underline col-md-4"
      style={{ width: '380px' }}
    >
      <div className="bg-light-gray flex flex-row mb-8 w-full">
        <ImageWithFallback
          src={event.wordpress?.logoImageURI}
          height="100"
          width="100"
          className="w-28 h-28"
        />

        <div className="flex flex-column py-3 px-3 flex-grow-1 justify-between">
          <div className="text-black">
            <div className="text-xl">{event.eventId}</div>
            <b className="flex text-sm leading-2 mb-2">
              {event.wordpress?.title}
            </b>
            {event.wordpress?.startDate && (
              <div className="text-md">
                {moment(event.wordpress.startDate)
                  .format('DD.MM.YYYY')}
              </div>
            )}
          </div>
          <button
            type="submit"
            className="py-1 px-3 bg-blue text-white font-weight-800 self-end"
          >
            Results
          </button>
        </div>
      </div>
    </Link>
  );
}

function EventTiles({ events }) {
  return (
    <div className="row">
      {events && events.length > 0 ? (
        events.map((event) => <EventTile event={event} />)
      ) : (
        <p className="lightText">No events available</p>
      )}
    </div>
  );
}

export function Events() {
  const dispatch = useDispatch();
  const eventsOperation = useSelector((state) => state.api.events);
  const seasonsOperation = useSelector((state) => state.api.seasons);
  const events = eventsOperation.current;

  const seasons = useMemo(() => {
    if (!seasonsOperation?.current?.length) {
      return [];
    }
    return seasonsOperation?.current;
  }, [seasonsOperation]);

  const [activeTabId, setActiveTabId] = useState('2023');

  useEffect(() => {
    if (!events?.length) dispatch(apiActions.getEvents());
  }, [dispatch]);

  useEffect(() => {
    dispatch(apiActions.getSeasons());
  }, [dispatch]);

  return (
    <Layout title="Event List" operation={eventsOperation}>
      <div className="flex relative flex-col">
        {seasons?.length ? (
          <Tabs
            tabs={seasons?.map?.(({ seasonId }) => ({
              label: seasonId,
              id: seasonId,
              key: seasonId,
              content: (
                <EventTiles
                  key={seasonId}
                  events={
                    events?.filter?.(
                      (event) => new Date(
                        event?.eventId.substring(0, 4),
                      )?.getFullYear?.() === Number(seasonId),
                    ) || []
                  }
                />
              ),
            }))}
            className="events-tabs"
            activeTabId={activeTabId}
            onChange={({ detail }) => {
              setActiveTabId(detail.activeTabId);
            }}
          />
        ) : null}
      </div>
    </Layout>
  );
}
