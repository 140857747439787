import { ConfidenceValue } from './ConfidenceValue';

export function ConfidenceColumn({ confidence }) {
  return (
    <ul>
      <li>
        Speed:
        {' '}
        <ConfidenceValue confidence={confidence?.speed} />
      </li>
      <li>
        Cadence:
        {' '}
        <ConfidenceValue confidence={confidence?.cadence} />
      </li>
      <li>
        Power:
        {' '}
        <ConfidenceValue confidence={confidence?.power} />
      </li>
      <li>
        HR:
        {' '}
        <ConfidenceValue confidence={confidence?.heartrate} />
      </li>
    </ul>
  );
}
