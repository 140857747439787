import {
  Box,
  Button,
  CollectionPreferences,
  Header,
  Table,
  TextFilter,
} from '@awsui/components-react';
import { useCollection } from '@awsui/collection-hooks';
import React, { useState } from 'react';
import './DataTable.css';

const tableColumns = ['rider', 'speed', 'cadence', 'power', 'heartrate'];
const POWER_HEADER = 'Power (watt)';
const SPEED_HEADER = 'Speed (m/s)';
const CADENCE_HEADER = 'Cadence';
const HEARTRATE_HEADER = 'Heartrate (bpm)';

const columns = [
  {
    id: 'rider',
    header: 'Rider',
    cell: (e) => `${e.UCIID} - ${e.FirstName} ${e.LastName}`,
    sortingField: 'UCIID',
    width: 320,
    minWidth: 290,
  },
  {
    id: 'speed',
    header: SPEED_HEADER,
    cell: (e) => e.Speed,
    sortingField: 'Speed',
    width: 140,
    minWidth: 140,
  },
  {
    id: 'cadence',
    header: CADENCE_HEADER,
    cell: (e) => e.RiderCadency,
    sortingField: 'RiderCadency',
    width: 120,
    minWidth: 120,
  },
  {
    id: 'power',
    header: POWER_HEADER,
    cell: (e) => e.RiderPower,
    sortingField: 'RiderPower',
    width: 150,
    minWidth: 150,
  },
  {
    id: 'heartrate',
    header: HEARTRATE_HEADER,
    cell: (e) => e.RiderHeartrate,
    sortingField: 'RiderHeartrate',
    width: 160,
    minWidth: 160,
  },
];

function EmptyState({
  title,
  subtitle,
  action = undefined,
}) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export default function DataTable(props) {
  const [tablePreferences, setTablePreferences] = useState({ visibleContent: tableColumns });
  const [filter, setFilter] = useState('');

  const {
    items,
    actions,
    collectionProps,
    filterProps,
  } = useCollection(
    props.items,
    {
      filtering: {
        empty: (
          <EmptyState
            title="No instances"
            subtitle="No instances to display."
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      sorting: {},
    },
  );

  return (
    <div className="dataTableWrapper">
      <Table
        {...collectionProps}
        trackBy="name"
        columnDefinitions={columns}
        items={(filter.length > 0 ? items.filter(((i) => `${i.UCIID} ${i.FirstName} ${i.LastName}`.toLowerCase()
          .includes(filter))) : items)}
        loadingText="Loading data"
        resizableColumns
        visibleColumns={tablePreferences.visibleContent}
        empty={(
          <Box textAlign="center" color="inherit">
            <b>No data</b>
            <Box
              padding={{ bottom: 's' }}
              variant="p"
              color="inherit"
            >
              No data to display.
            </Box>
          </Box>
        )}
        filter={(
          <TextFilter
            {...filterProps}
            filteringPlaceholder="Find rider"
            onDelayedChange={({ detail }) => setFilter(detail.filteringText.trim()
              .toLowerCase())}
          />
        )}
        header={(
          <Header>
            Latest rider data
          </Header>
        )}
        preferences={(
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={tablePreferences}
            onConfirm={({ detail }) => setTablePreferences(detail)}
            visibleContentPreference={{
              title: 'Select visible columns',
              options: [
                {
                  label: 'Data points',
                  options: [
                    {
                      id: 'rider',
                      label: 'Rider',
                      editable: false,
                    },
                    {
                      id: 'speed',
                      label: SPEED_HEADER,
                    },
                    {
                      id: 'cadence',
                      label: CADENCE_HEADER,
                    },
                    {
                      id: 'power',
                      label: POWER_HEADER,
                    },
                    {
                      id: 'heartrate',
                      label: HEARTRATE_HEADER,
                    },
                  ],
                },
              ],
            }}
          />
        )}
      />
    </div>
  );
}
