import './index.css';
import { useHistory } from 'react-router-dom';
import { Button } from '@awsui/components-react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo } from 'react';

import Layout from '../../components/Layout';
import { fuzzySort } from '../../utils/utils';
import { NewGrid } from '../../components/Grid';
import { useHookParam } from '../../hooks/useRouteParam';
import { apiActions } from '../../store/actions/api.actions';

export function Event() {
  const history = useHistory();
  const renderActions = ({ row }) => (
    <div className="text-right">
      {row.original?.RaceStatus === 'LIVE' && (
        <Button
          className="h-8 mr-4 bg-green-500 text-white border-0 border-opacity-50"
          onClick={() => {
            history.push(
              `/race/${row.original?.EventID}/${row.original?.RaceID}/live`,
            );
          }}
        >
          Live Race
        </Button>
      )}
      {row.original?.RaceStatus === 'FINISHED' && (
        <Button
          className="h-8 mr-4 border-green-500 text-green-500 border-2 border-opacity-50"
          onClick={() => {
            history.push(
              `/race/${row.original?.EventID}/${row.original?.RaceID}/results`,
            );
          }}
        >
          Results
        </Button>
      )}
      <Button
        className="h-8 mr-4 border-green-500 text-green-500 border-2 border-opacity-50"
        onClick={() => {
          history.push(
            `/race/${row.original?.EventID}/${row.original?.RaceID}/start-list`,
          );
        }}
      >
        Start List
      </Button>
      <Button
        className="h-8 border-green-500 text-green-500 border-2 border-opacity-50"
        onClick={() => {
          history.push(
            `/race/${row.original?.EventID}/${row.original?.RaceID}/race`,
          );
        }}
      >
        Race
      </Button>
    </div>
  );
  const dispatch = useDispatch();

  const races = useSelector((state) => state.api.races.current);
  const liveRace = useSelector((state) => state.api.liveRace.current);
  const eventsOperation = useSelector((state) => state.api.events);

  const {
    match: event,
    param: eventId,
  } = useHookParam(
    'eventId',
    eventsOperation,
  );

  useEffect(() => {
    dispatch(apiActions.getRidersList(eventId.substring(0, 4)));
  }, [dispatch]);

  useEffect(() => {
    if (!eventsOperation?.current?.length) {
      dispatch(apiActions.getEvents());
    }
  }, [eventsOperation?.current?.length]);

  useEffect(() => {
    if (eventId !== null) {
      dispatch(apiActions.getRaces({ eventId }));
      dispatch(apiActions.getLiveRace({ eventId }));
    }

    return () => {
      dispatch(apiActions.stopPollingLiveRace());
    };
  }, [eventId]);

  const pageTitle = useMemo(() => {
    if (!event) {
      return undefined;
    }
    return `Race List - ${event?.eventId}`;
  }, [event]);

  const pageTitleBold = useMemo(() => {
    if (!event) {
      return undefined;
    }
    return ` ${event.wordpress?.title}`;
  }, [event]);

  const isRaceLive = useCallback(
    (race) => liveRace?.RaceID && liveRace?.RaceID === race.RaceID,
    [liveRace],
  );

  const columns = React.useMemo(
    () => [
      {
        accessorFn: (row) => `${row.RaceID}`,
        id: 'RaceID',
        header: 'Race ID',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
        size: 2,
      },
      {
        accessorFn: (row) => `${row.RaceStatus}`,
        id: 'RaceStatus',
        header: 'Status',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
        size: 2,
      },
      {
        accessorFn: (row) => `${row.RaceType}`,
        id: 'RaceType',
        header: 'Type',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
        size: 2,
      },
      {
        accessorFn: (row) => `${row.Gender}`,
        id: 'Gender',
        header: 'Gender',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
        size: 2,
      },
      {
        header: 'League',
        accessorFn: (row) => `${row.League}`,
        id: 'League',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
        size: 2,
      },
      {
        header: 'Laps',
        accessorFn: (row) => `${row.Laps}`,
        id: 'Laps',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
        size: 2,
      },
      {
        header: 'Heat',
        accessorFn: (row) => `${row.Heat}`,
        id: 'Heat',
        cell: (info) => `Heat ${info.getValue()}`,
        footer: (props) => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
        size: 2,
      },
      {
        header: 'Actions',
        id: 'Actions',
        show: true,
        size: 6,
        cell: renderActions,
      },
    ],
    [],
  );

  return (
    <Layout
      title={pageTitle}
      titleBold={pageTitleBold}
      subtitle={event?.wordpress?.startDate}
      operation={eventsOperation}
    >
      {races && (
        <NewGrid
          data={races}
          displayAllRows
          options={{
            isRowActive: (race) => isRaceLive(race),
          }}
          columns={columns}
        />
      )}
      {/* { renderedRaces() } */}
    </Layout>
  );
}
