import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import { apiActions } from '../../store/actions/api.actions';

export function Standings() {
  const dispatch = useDispatch();
  const standingsData = useSelector((state) => state.api.standings.current);

  useEffect(() => {
    dispatch(apiActions.getStandings(2023));
  }, [dispatch]);

  console.log(standingsData);
  const pageTitle = 'General classification';
  return (
    <Layout title={pageTitle}>
      <div className="mt-12 ">
        <div className="row justify-content-md-between">
          {standingsData && standingsData.map((item) => (
            <div className="card col-5 mb-5 mx-5">
              <div className="card-body">
                <h5 className="card-title h4">{item.League}</h5>
                <table className="table mb-5">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">Bib</th>
                      <th scope="col">UCI ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.Standings.map((standing) => (
                      <tr key={standing.UCIID}>
                        <th scope="row">{standing.Rank}</th>
                        <td>{standing.Bib}</td>
                        <td>{standing.UCIID}</td>
                        <td>
                          {standing.FirstName}
                          {' '}
                          {standing.LastName}
                        </td>
                        <td>{standing.PointsTotal}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}

        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </Layout>
  );
}
