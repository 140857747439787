import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../images/logo.png';

export function HeaderLogo() {
  return (
    <Link to="/">
      <img src={logo} className="h-full" alt="logo" />
    </Link>
  );
}
