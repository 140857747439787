import sagaMiddlewareFactory from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import { rootSagas } from './sagas/root.sagas';
import { initialRootState, rootReducers } from './reducers/root.reducer';

const sagaMiddleware = sagaMiddlewareFactory();

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  const composeDevToolsOptions = {};
  composeEnhancers = composeWithDevTools(composeDevToolsOptions);
}

const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware));

export const store = createStore(rootReducers, initialRootState, enhancers);

sagaMiddleware.run(rootSagas);
