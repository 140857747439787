import './index.css';
import axios from 'axios';
import CSVReader from 'react-csv-reader';
import { useEffect, useState } from 'react';
import JsonTable from 'react-json-table-v2';
/// TO MOVE TO SAGA etc

const instance = axios.create();

instance.defaults.headers = {
  'x-api-key': 'R9C^ShnG^v3^76-mKGBFx4lN=FLjIEa4',
};
instance.defaults.baseURL = 'http://qj1dz7iz94.execute-api.eu-west-1.amazonaws.com/dev';

const handleSave = (items, setResponse) => {
  instance
    .post('/StorePreSeasonQuestionnaire', items)
    .then((res) => {
      console.log(res);
      setResponse(res);
    })
    .catch((e) => {
      console.log(e);
      setResponse(e.message);
    });
};

const getRiders = (setItems) => {
  instance.get('/v1/riders')
    .then((response) => {
      console.log(response);
      setItems(response.data);
    });
};

const handleForce = (data, fileInfo, setItems) => setItems(data);

const papaparseOptions = {
  header: true,
  transform: (header) => (header === '' ? null : header),
};

export function Questioner() {
  const [items, setItems] = useState([]);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    getRiders(setItems);
  }, []);

  return (
    <div className="pageWrapper">
      <div className="container">
        <CSVReader
          cssClass="react-csv-input"
          label="Select CSV"
          onFileLoaded={(data, fileInfo) => handleForce(data, fileInfo, setItems)}
          parserOptions={papaparseOptions}
        />

        <div className="table-responsive">
          <JsonTable className="table" rows={items} />
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleSave(items, setResponse)}
        >
          Save changes
        </button>
      </div>

      {response && (
        <div className="alert alert-warning" role="alert">
          {response}
        </div>
      )}
    </div>
  );
}
