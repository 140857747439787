import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { ButtonDropdown } from '@awsui/components-react';

import avatar from '../../images/avatar.png';

const userMenuItems = [
  {
    id: 'settings',
    text: 'Settings',
  },
  {
    text: 'Logout',
    id: 'logout',
  },
];

export function UserMenu({ currentUserName }) {
  const history = useHistory();

  const handleItemClick = async ({ detail }) => {
    if (detail.id === 'logout') {
      await Auth.signOut();
      window.location.href = '/';
    } else if (detail.id === 'settings') {
      history.push({
        pathname: '/user-settings',
      });
    }
  };

  return (
    <ButtonDropdown
      id="header-user-dropdown"
      items={userMenuItems}
      onItemClick={handleItemClick}
      className="flex flex-row align-center"
    >
      <span className="flex flex-row items-center text-medium font-weight-600 text-white">
        <img
          src={avatar}
          className="rounded-full w-8 h-8 mr-2 end-0"
          alt="User avatar"
        />
        {currentUserName}
      </span>
    </ButtonDropdown>
  );
}
