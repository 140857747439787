import { Tabs } from '@awsui/components-react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';

import { useColumns } from './useColumns';
import logo from '../../images/uci-logo.png';
import Layout from '../../components/Layout';
import { NewGrid } from '../../components/Grid';
import { apiActions } from '../../store/actions/api.actions';

export function RiderList() {
  const dispatch = useDispatch();

  const riderListOperation = useSelector((state) => state.api.riders);
  const seasonsOperation = useSelector((state) => state.api.seasons);

  const seasons = useMemo(() => {
    if (!seasonsOperation?.current?.length) {
      return [];
    }
    return seasonsOperation?.current;
  }, [seasonsOperation]);

  const [activeTabId, setActiveTabId] = useState('2023');

  useEffect(() => {
    dispatch(apiActions.getSeasons());
  }, [dispatch]);

  useEffect(() => {
    if (activeTabId) {
      dispatch(apiActions.getRidersList(activeTabId));
    }
  }, [activeTabId]);

  const columns = useColumns();

  return (
    <Layout title="Rider List">
      {seasons?.length ? (
        <Tabs
          tabs={seasons?.map?.(({ seasonId }) => ({
            label: seasonId,
            id: seasonId,
            key: seasonId,
            content: riderListOperation?.status?.isLoading ? (
              <img
                alt="loader"
                className="position-absolute top-50 start-50 translate-middle blob z-20 "
                src={logo}
              />
            ) : (
              <NewGrid
                data={
                  riderListOperation?.current?.items?.sort(
                    (a, b) => a.Bib - b.Bib,
                  ) || []
                }
                columns={columns}
              />
            ),
          }))}
          className="events-tabs"
          activeTabId={activeTabId}
          onChange={({ detail }) => {
            setActiveTabId(detail.activeTabId);
          }}
        />
      ) : null}
    </Layout>
  );
}
