/* eslint camelcase: 0 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import { Amplify } from 'aws-amplify';
import App from './App';
import { store } from './store/store';
import awsConfig from './aws-exports';

// eslint-disable-next-line no-shadow
function configureAwsAmplify(awsConfig) {
  const {
    aws_region,
    aws_cognito_userPoolId,
    aws_cognito_clientId,
  } = awsConfig;

  Amplify.configure({
    Auth: {
      region: aws_region,
      userPoolId: aws_cognito_userPoolId,
      userPoolWebClientId: aws_cognito_clientId,
    },
  });
}

configureAwsAmplify(awsConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
