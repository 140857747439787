import gql from 'graphql-tag';

export default gql(`
query($raceId: String!, $uciId: String!, $limit: Int, $nextDataToken: String, $nextTrackingToken: String) {
  getRiderLiveTracking(raceId: $raceId, uciId: $uciId, limit: $limit, nextToken: $nextTrackingToken) {
    items {
      Acc
      RiderRank
      Lng
      Distance
      SpeedMax
      DistanceNext
      ServerTimeStamp
      State
      DistanceFirst
      RaceID
      SeasonID
      DistanceProj
      UCIID
      EventID
      SpeedAvg
      Bib
      Speed
      EventTimeStamp
      Lat
    }
    nextToken
  }
  getRiderLiveData(raceId: $raceId, uciId: $uciId, limit: $limit, nextToken: $nextDataToken) {
    items {
      Bib
      EventID
      EventTimeStamp
      RaceID
      RiderCadency
      RiderHeartrate
      RiderPower
      SeasonID
      ServerTimeStamp
      UCIID
    }
    nextToken
  }
}`);
