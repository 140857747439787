import gql from 'graphql-tag';

export default gql(`
query($raceId: String!, $nextToken: String) {
  getRaceResults(raceId: $raceId, nextToken: $nextToken) {
    items {
      Bib
      Distance
      EventID
      FirstName
      Gender
      Heat
      Laps
      LastName
      League
      Message
      NOC
      RaceID
      RaceLaps
      RaceName
      RaceSpeed
      RaceTime
      RaceType
      Rank
      Round
      SeasonID
      ShortTVName
      State
      Status
      Team
      TimeStamp
      TotalHeats
      TotalRounds
      UCIID
      Diff
    }
    nextToken
  }
}`);
