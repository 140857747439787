import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import '../Header.scss';
import { UserMenu } from './UserMenu';
import { HeaderLogo } from './HeaderLogo';
import { HeaderLinks } from './HeaderLinks';

export default function Header() {
  const [currentUserName, setCurrentUserName] = useState();

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();

      setCurrentUserName(user.username);
    })();
  }, []);

  return (
    <header className="px-8 py-2 bg-blue-primary h-16 position-relative">
      <nav className=" w-full h-full flex flex-row container position-relative">
        <HeaderLogo />
        <span className="flex flex-row">
          <HeaderLinks />
        </span>
        <span className="flex-grow-1" />
        <UserMenu currentUserName={currentUserName} />
      </nav>
    </header>
  );
}
