import {
  all, call, delay, put, race, take, takeEvery,
} from 'redux-saga/effects';
import axios from 'axios';
import AWSAppSyncClient from 'aws-appsync';
import {
  API_GET_ELIMINATED_RIDERS,
  API_GET_EVENTS,
  API_GET_LIVE_DATA,
  API_GET_LIVE_RACE,
  API_GET_LIVE_RIDER_DATA,
  API_GET_RACE_RESULTS,
  API_GET_RACE_RESULTS_STATS,
  API_GET_RIDER_STATS,
  API_GET_COMPARED_RIDER_STATS,
  API_GET_RACES,
  API_GET_RIDER_DETAILS,
  API_GET_COMPARED_RIDER_DETAILS,
  API_GET_RIDERS_IN_RACE,
  API_GET_RIDERS_LIST,
  API_GET_SEASONS,
  API_GET_STANDINGS,
  API_GET_UI_SETTINGS,
  API_LIST_COUNTRIES,
  API_POLLING_INTERVAL,
  API_SET_UI_SETTINGS,
  API_STOP_LIVE_RIDER_DATA_POLLING,
  API_STOP_POLLING,
  API_STOP_POLLING_LIVE_RACE,
  API_TOGGLE_RIDER_CONFIDENCE,
} from '../constants/api.constants';
import { apiActions } from '../actions/api.actions';

import awsconfig from '../../aws-exports';
import QueryAllEvents from '../../graphql/QueryAllEvents';
import QueryGetSeasons from '../../graphql/QueryGetSeasons';
import QueryAllRaces from '../../graphql/QueryAllRaces';
import QueryCurrentRiderData from '../../graphql/QueryCurrentRiderData';

import QueryLiveRace from '../../graphql/QueryLiveRace';
import QueryLiveDataRiderInRace from '../../graphql/QueryLiveDataRiderInRace';
import QueryRidersInRace from '../../graphql/QueryRidersInRace';
import QueryGetRiderConfidence from '../../graphql/QueryGetRiderConfidence';
import QueryGetRider from '../../graphql/QueryGetRider';
import QueryGetRaceResults from '../../graphql/QueryGetRaceResults';

import QueryGetRiderStartLists from '../../graphql/QueryGetRiderStartLists';
import QueryGetRiderResultsInRaces from '../../graphql/QueryGetRiderResultsInRaces';
import QueryAllRiders from '../../graphql/QueryAllRiders';
import MutationSetRiderConfidence from '../../graphql/MutationSetRiderConfidence';
import QueryGetUISettings from '../../graphql/QueryGetUISettings';
import MutationSetUISettings from '../../graphql/MutationSetUISettings';
import QueryGetRiderResultsStats from '../../graphql/QueryGetRiderResultsStats';
import QueryGetEliminatedRiders from '../../graphql/QueryGetEliminatedRiders';
import queryGetSeasonStandings from '../../graphql/QueryGetSeasonStandings';
import QueryGetRiderStats from '../../graphql/QueryGetRiderStats';

const instance = axios.create();

export const apiSagas = () => {
  let client;

  function initClient() {
    client = new AWSAppSyncClient({
      disableOffline: true,
      url: awsconfig.aws_appsync_graphqlEndpoint,
      region: awsconfig.aws_region,
      auth: {
        type: awsconfig.aws_appsync_authenticationType,
        apiKey: awsconfig.aws_appsync_apiKey,
      },
    });
  }

  function* getEvents(action) {
    try {
      if (!client) initClient();

      const query = QueryAllEvents;

      const response = yield call(client.query, {
        query,
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only',
      });

      yield put(apiActions.getEventsSuccess(response.data.getEvents));
    } catch (error) {
      console.error('An error occurred while getting events', error);
      yield put(apiActions.getEventsError(action?.payload?.request, error));
    }
  }

  function* getSeasons(action) {
    try {
      if (!client) initClient();

      const query = QueryGetSeasons;

      const response = yield call(client.query, {
        query,
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only',
      });

      yield put(apiActions.getSeasonsSuccess(response.data.getSeasons));
    } catch (error) {
      console.error('An error occurred while getting seasons', error);
      yield put(apiActions.getSeasonsError(action?.payload?.request, error));
    }
  }

  function* getLiveRace(action) {
    try {
      if (action.payload.poll === false) {
        if (!client) initClient();
        const query = QueryLiveRace;
        const { eventId } = action.payload;

        const response = yield call(client.query, {
          query,
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
          variables: {
            eventId,
          },
        });

        let raceData;
        if (response.data?.getLiveRace?.length > 0) {
          raceData = response.data.getLiveRace[0].Race;
        }

        yield put(apiActions.getLiveRaceSuccess(raceData));
      } else {
        yield race([
          // eslint-disable-next-line no-use-before-define
          call(pollLiveRace, action),
          take(API_STOP_POLLING_LIVE_RACE),
        ]);
      }
    } catch (error) {
      console.error(
        'An error occurred while starting polling live race',
        error,
      );
      yield put(apiActions.getLiveRaceError(action?.payload?.request, error));
    }
  }

  function* pollLiveRace(action) {
    while (true) {
      try {
        if (!client) initClient();

        const query = QueryLiveRace;
        const { eventId } = action.payload;

        const response = yield call(client.query, {
          query,
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
          variables: {
            eventId,
          },
        });

        let raceData;
        if (response.data?.getLiveRace?.length > 0) {
          raceData = response.data.getLiveRace[0].Race;
        }

        yield put(apiActions.getLiveRaceSuccess(raceData));
        yield delay(API_POLLING_INTERVAL);
      } catch (error) {
        console.error('An error occurred while getting live race', error);
        yield put(apiActions.getLiveRaceError(action?.payload?.request, error));
      }
    }
  }

  function* getRaceResults(action) {
    const { raceId } = action.payload;
    try {
      if (!client) initClient();

      const query = QueryGetRaceResults;

      const { nextToken } = action.payload;

      const response = yield call(client.query, {
        query,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          raceId,
          nextToken,
        },
      });

      yield put(
        apiActions.getRaceResultsSuccess(raceId, response.data.getRaceResults),
      );
    } catch (error) {
      console.error('An error occurred while getting the race results', error);
      yield put(
        apiActions.getRaceResultsError(raceId, action?.payload?.request, error),
      );
    }
  }

  function* getRaceResultsStats(action) {
    const { raceId } = action.payload;
    try {
      if (!client) initClient();

      const query = QueryGetRiderResultsStats;

      const { nextToken } = action.payload;

      const response = yield call(client.query, {
        query,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          raceId,
          nextToken,
        },
      });
      yield put(
        apiActions.getRaceResultsStatsSuccess(
          raceId,
          response.data.getRidersResultsStats,
        ),
      );
    } catch (error) {
      console.error('An error occurred while getting the race results', error);
      yield put(
        apiActions.getRaceResultsStatsError(
          raceId,
          action?.payload?.request,
          error,
        ),
      );
    }
  }

  function* getRiderStats(action) {
    const { seasonId, uciId } = action.payload;
    try {
      if (!client) initClient();

      const query = QueryGetRiderStats;
      const response = yield call(client.query, {
        query,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          seasonId,
          uciId,
        },
      });
      yield put(
        apiActions.getRiderStatsSuccess(
          uciId,
          response.data.getRiderStats,
        ),
      );
    } catch (error) {
      console.error('An error occurred while getting the rider stats results', error);
      yield put(
        apiActions.getRiderStatsError(
          uciId,
          action?.payload?.request,
          error,
        ),
      );
    }
  }

  function* getComparedRiderStats(action) {
    const { seasonId, uciId } = action.payload;
    try {
      if (!client) initClient();

      const query = QueryGetRiderStats;
      const response = yield call(client.query, {
        query,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          seasonId,
          uciId,
        },
      });
      yield put(
        apiActions.getComparedRiderStatsSuccess(
          uciId,
          response.data.getRiderStats,
        ),
      );
    } catch (error) {
      console.error('An error occurred while getting the rider stats results', error);
      yield put(
        apiActions.getComparedRiderStatsError(
          uciId,
          action?.payload?.request,
          error,
        ),
      );
    }
  }

  function* getRaces(action) {
    try {
      if (!client) initClient();

      const query = QueryAllRaces;
      const { eventId } = action.payload;

      const response = yield call(client.query, {
        query,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          eventId,
        },
      });

      yield put(
        apiActions.getRacesSuccess(response.data.getRacesForEvent.items),
      );
    } catch (error) {
      console.error('An error occurred while getting races', error);
      yield put(apiActions.getRacesError(action?.payload?.request, error));
    }
  }

  function* getRidersInRace(action) {
    try {
      if (!client) initClient();

      const query = QueryRidersInRace;
      const { raceId } = action.payload;

      const response = yield call(client.query, {
        query,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          raceId,
        },
      });

      yield put(
        apiActions.getRidersInRaceSuccess(response.data.getRidersInRace.items),
      );
    } catch (error) {
      console.error('An error occurred while getting races', error);
      yield put(
        apiActions.getRidersInRaceError(action?.payload?.request, error),
      );
    }
  }

  function* getRidersList(action) {
    try {
      if (!client) initClient();

      const query = QueryAllRiders;
      // const raceId = action.payload.raceId;

      // TODO: paginate riders list
      const response = yield call(client.query, {
        query,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          limit: 999,
          seasonId: action.payload.seasonId,
        },
      });

      yield put(
        apiActions.getRidersListSuccess(response.data.listRiders || []),
      );
    } catch (error) {
      console.error('An error occurred while getting the race list', error);
      yield put(apiActions.getRidersListError(error));
    }
  }

  function* getRiderDetails(action) {
    const { uciId } = action.payload;
    try {
      if (!client) initClient();

      const queryGetRider = QueryGetRider;
      const queryRiderStartLists = QueryGetRiderStartLists;
      const queryRiderResultsInRaces = QueryGetRiderResultsInRaces;
      const queryGetRiderConfidence = QueryGetRiderConfidence;

      const responseGetRider = yield call(client.query, {
        query: queryGetRider,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          uciId,
          seasonId: '2023',
        },
      });

      const responseGetRiderStartLists = yield call(client.query, {
        query: queryRiderStartLists,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          uciId,
        },
      });

      const responseGetRiderResultsInRaces = yield call(client.query, {
        query: queryRiderResultsInRaces,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          uciId,
        },
      });

      const responseGetRiderConfidence = yield call(client.query, {
        query: queryGetRiderConfidence,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          uciId,
        },
      });

      // console.log('responseGetRiderStartLists', responseGetRiderConfidence);
      yield put(
        apiActions.getRiderDetailsSuccess(
          uciId,
          responseGetRider.data.getRider,
          responseGetRiderStartLists.data.getRiderStartLists,
          responseGetRiderResultsInRaces.data.getRiderResultsInRaces,
          responseGetRiderConfidence.data?.getRiderConfidence?.confidence || {
            speed: false,
            cadence: false,
            heartrate: false,
            power: false,
          },
        ),
      );
    } catch (error) {
      console.error('An error occurred while getting the rider details', error);
      yield put(apiActions.getRiderDetailsError(uciId, error));
    }
  }

  function* getComparedRiderDetails(action) {
    const { uciId } = action.payload;
    try {
      if (!client) initClient();

      const queryGetRider = QueryGetRider;
      const queryRiderStartLists = QueryGetRiderStartLists;
      const queryRiderResultsInRaces = QueryGetRiderResultsInRaces;
      const queryGetRiderConfidence = QueryGetRiderConfidence;

      const responseGetRider = yield call(client.query, {
        query: queryGetRider,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          uciId,
          seasonId: '2023',
        },
      });

      const responseGetRiderStartLists = yield call(client.query, {
        query: queryRiderStartLists,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          uciId,
        },
      });

      const responseGetRiderResultsInRaces = yield call(client.query, {
        query: queryRiderResultsInRaces,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          uciId,
        },
      });

      const responseGetRiderConfidence = yield call(client.query, {
        query: queryGetRiderConfidence,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          uciId,
        },
      });

      // console.log('responseGetRiderStartLists', responseGetRiderConfidence);
      yield put(
        apiActions.getComparedRiderDetailsSuccess(
          uciId,
          responseGetRider.data.getRider,
          responseGetRiderStartLists.data.getRiderStartLists,
          responseGetRiderResultsInRaces.data.getRiderResultsInRaces,
          responseGetRiderConfidence.data?.getRiderConfidence?.confidence || {
            speed: false,
            cadence: false,
            heartrate: false,
            power: false,
          },
        ),
      );
    } catch (error) {
      console.error('An error occurred while getting the rider details', error);
      yield put(apiActions.getComparedRiderDetailsError(uciId, error));
    }
  }

  function* pollLiveData(action) {
    while (true) {
      try {
        if (!client) initClient();

        const query = QueryCurrentRiderData;
        const { raceId } = action.payload;
        const { riderIds } = action.payload;

        // eslint-disable-next-line
        const response = yield all(
          // eslint-disable-next-line no-loop-func
          riderIds.map((id) => call(client.query, {
            query,
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
            variables: {
              raceId,
              uciId: id,
            },
          })),
        );

        let latestTimeStamp;
        const values = [];
        response.forEach((res) => {
          if (res.data.getCurrentRiderData.length === 0) return;

          const { EventTimeStamp } = res.data.getCurrentRiderData[0];

          if (
            latestTimeStamp === undefined
            || EventTimeStamp > latestTimeStamp
          ) {
            latestTimeStamp = EventTimeStamp;
          }
          values.push(res.data.getCurrentRiderData[0]);
        });

        if (action.payload.poll !== false) {
          yield put(apiActions.pollingSuccess(values));
          yield put(apiActions.setLatestTimeStamp(latestTimeStamp));
          yield delay(API_POLLING_INTERVAL);
        }
      } catch (error) {
        console.error('An error occurred while polling live data', error);
        yield put(apiActions.pollingError(error));

        // Once the polling has encountered an error it should be stopped immediately
        yield put(apiActions.stopPolling());
      }
    }
  }

  function* pollLiveRiderData(action) {
    while (true) {
      try {
        if (!client) initClient();

        const query = QueryCurrentRiderData;
        const { raceId } = action.payload;

        const response = yield call(client.query, {
          query,
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
          variables: {
            raceId,
            // limit: 50
          },
        });

        // if (action.payload.poll !== false) {
        yield put(apiActions.liveRiderDataPollingSuccess(response));
        yield delay(API_POLLING_INTERVAL);
        // }
      } catch (error) {
        console.error('An error occurred while polling live data', error);
        yield put(apiActions.liveRiderDataPollingError(error));

        // Once the polling has encountered an error it should be stopped immediately
        yield put(apiActions.stopLiveRiderDataPolling());
      }
    }
  }

  function* toggleRiderConfidence(action) {
    const { uciId } = action.payload;
    try {
      if (!client) initClient();

      const mutationSetRiderConfidence = MutationSetRiderConfidence;

      const response = yield call(client.mutate, {
        mutation: mutationSetRiderConfidence,
        errorPolicy: 'ignore',
        variables: {
          uciId,
          confidence: action.payload.confidence,
        },
      });

      console.log('responseGetRiderStartLists', response);
      yield put(
        apiActions.toggleRiderConfidenceSuccess(
          uciId,
          action.payload.confidence,
        ),
      );
    } catch (error) {
      console.error(
        'An error occurred while toggling the rider confidence',
        error,
      );
      yield put(apiActions.toggleRiderConfidenceError(uciId, error));
    }
  }

  function* getLiveRiderData(action) {
    try {
      if (!client) initClient();

      const query = QueryCurrentRiderData;
      const { raceId } = action.payload;

      const response = yield call(client.query, {
        query,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          raceId,
          // limit: 50
        },
      });

      yield put(apiActions.getLiveRiderDataSuccess(response));
      yield delay(API_POLLING_INTERVAL);
      yield put(apiActions.setLiveRiderDataPolling());

      console.log('[API_POLLING_INTERVAL]', API_POLLING_INTERVAL);
      yield race([
        call(pollLiveRiderData, action),
        take(API_STOP_LIVE_RIDER_DATA_POLLING),
      ]);
    } catch (error) {
      console.error('An error occurred while querying live rider data', error);
      yield put(
        apiActions.getLiveRiderDataError(action?.payload?.request, error),
      );
    }
  }

  function* getLiveData(action) {
    try {
      if (!client) initClient();

      const query = QueryLiveDataRiderInRace;
      const { raceId } = action.payload;
      const { riderIds } = action.payload;

      const response = yield all(
        riderIds.map((id) => call(client.query, {
          query,
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
          variables: {
            raceId,
            uciId: id,
            // limit: 50
          },
        })),
      );

      let latestTimeStamp;
      const values = [];
      response.forEach((res) => {
        if (
          (!res.data.getRiderLiveData
            || !res.data.getRiderLiveData.items
            || res.data.getRiderLiveData.items.length === 0)
          && (!res.data.getRiderLiveTracking
            || !res.data.getRiderLiveTracking.items
            || res.data.getRiderLiveTracking.items.length === 0)
        ) {
          return;
        }

        const data = res.data.getRiderLiveData.items;
        const tracking = res.data.getRiderLiveTracking.items;

        if (data) {
          data.forEach((d) => {
            if (
              latestTimeStamp === undefined
              || d.EventTimeStamp > latestTimeStamp
            ) {
              latestTimeStamp = d.EventTimeStamp;
            }
          });
        }
        if (tracking) {
          tracking.forEach((d) => {
            if (
              latestTimeStamp === undefined
              || d.EventTimeStamp > latestTimeStamp
            ) {
              latestTimeStamp = d.EventTimeStamp;
            }
          });
        }

        values.push({
          tracking,
          data,
        });
      });

      yield put(apiActions.getLiveDataSuccess(values));
      yield put(apiActions.setLatestTimeStamp(latestTimeStamp));
      yield delay(API_POLLING_INTERVAL);
      yield put(apiActions.setPolling());
      yield race([call(pollLiveData, action), take(API_STOP_POLLING)]);
    } catch (error) {
      console.error('An error occurred while getting historic data', error);
      yield put(apiActions.getLiveDataError(action?.payload?.request, error));
    }
  }

  function* getEliminatedRiders(action) {
    const raceId = action.payload;

    try {
      if (!client) initClient();

      const query = QueryGetEliminatedRiders;

      const response = yield call(client.query, {
        query,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          raceId,
        },
      });

      yield put(
        apiActions.getEliminatedRidersSuccess(response.data.getEliminatedRiders),
      );
    } catch (error) {
      console.error(
        'An error occurred while getting the getEliminatedRiders',
        error,
      );
      yield put(
        apiActions.getEliminatedRidersError(action?.payload?.request, error),
      );
    }
  }

  function* getStandings(action) {
    const seasonId = action.payload;

    try {
      if (!client) initClient();

      const query = queryGetSeasonStandings;

      const response = yield call(client.query, {
        query,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        variables: {
          seasonId,
        },
      });

      yield put(
        apiActions.getStandingsSuccess(response.data.getSeasonStandings),
      );
    } catch (error) {
      console.error(
        'An error occurred while getting the getStandingsError',
        error,
      );
      yield put(apiActions.getStandingsError(action?.payload?.request, error));
    }
  }

  function* getUISettings(action) {
    try {
      if (!client) initClient();

      const query = QueryGetUISettings;

      const response = yield call(client.query, {
        query,
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      });

      yield put(
        apiActions.getUISettingsSuccess(JSON.parse(response.data.getUISettings)),
      );
    } catch (error) {
      console.error('An error occurred while getting the UI settings', error);
      yield put(apiActions.getUISettingsError(action?.payload?.request, error));
    }
  }

  function* setUISettings(action) {
    const preferences = action.payload;

    try {
      if (!client) initClient();

      const mutation = MutationSetUISettings;

      const response = yield call(client.mutate, {
        mutation,
        variables: {
          preferences: JSON.stringify(preferences),
        },
        errorPolicy: 'ignore',
      });

      yield put(apiActions.setUISettingsSuccess(response.data));
    } catch (error) {
      console.error('An error occurred while getting the UI settings', error);
      yield put(apiActions.setUISettingsError(action?.payload?.request, error));
    }
  }

  function* listCountries() {
    try {
      if (!client) initClient();
      const response = yield call(
        instance.get,
        'https://restcountries.com/v3.1/all',
      );
      // console.log('listCountries', response);
      yield put(apiActions.listCountriesSuccess(response.data));
    } catch (error) {
      console.error('An error occurred while listing countries', error);
      yield put(apiActions.listCountriesError(error));
    }
  }

  return {
    * watcher() {
      yield all([takeEvery(API_GET_RACES, getRaces)]);
      yield all([takeEvery(API_GET_RACE_RESULTS, getRaceResults)]);
      yield all([takeEvery(API_GET_RACE_RESULTS_STATS, getRaceResultsStats)]);
      yield all([takeEvery(API_GET_RIDER_STATS, getRiderStats)]);
      yield all([takeEvery(API_GET_COMPARED_RIDER_STATS, getComparedRiderStats)]);
      yield all([takeEvery(API_GET_RIDERS_IN_RACE, getRidersInRace)]);
      yield all([takeEvery(API_GET_RIDERS_LIST, getRidersList)]);
      yield all([takeEvery(API_GET_RIDER_DETAILS, getRiderDetails)]);
      yield all([takeEvery(API_GET_COMPARED_RIDER_DETAILS, getComparedRiderDetails)]);
      yield all([takeEvery(API_GET_EVENTS, getEvents)]);
      yield all([takeEvery(API_GET_SEASONS, getSeasons)]);
      yield all([takeEvery(API_GET_LIVE_DATA, getLiveData)]);
      yield all([takeEvery(API_GET_UI_SETTINGS, getUISettings)]);
      yield all([takeEvery(API_SET_UI_SETTINGS, setUISettings)]);
      yield all([takeEvery(API_GET_LIVE_RIDER_DATA, getLiveRiderData)]);
      yield all([
        takeEvery(API_TOGGLE_RIDER_CONFIDENCE, toggleRiderConfidence),
      ]);
      yield all([takeEvery(API_GET_LIVE_RACE, getLiveRace)]);
      yield all([takeEvery(API_LIST_COUNTRIES, listCountries)]);
      yield all([takeEvery(API_GET_ELIMINATED_RIDERS, getEliminatedRiders)]);
      yield all([takeEvery(API_GET_STANDINGS, getStandings)]);
    },
  };
};
