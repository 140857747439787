const awsmobile =  {
    "aws_appsync_graphqlEndpoint": "https://vq2nn6iybzesvpaaui2u6xsa6i.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ssxgnazcnffgvniqubz5ppmxhq",
    "aws_cognito_userPoolId": "eu-west-1_ZRN15ODGV",
    "aws_cognito_clientId": "2m36njvghfqdsue2fu8v9k23ep",
};

export default awsmobile;

