import React, { useMemo, useEffect } from 'react';

import { EventRacesResults } from '../RiderInfo/EventRacesResults';
import { RiderPersonalDetails } from '../RiderInfo/RiderPersonalDetails';

export function SingleEventResultsContainer({
  riderId,
  riderDetails,
  raceId,
  fetchRiderStats,
}) {
  useEffect(() => {
    fetchRiderStats(riderId, raceId?.slice(0, 6));
  }, [riderId, raceId]);

  const currRace = useMemo(() => {
    if (!riderDetails?.riderResultsInRaces?.items?.length) {
      return {};
    }
    const races = {};
    riderDetails.riderResultsInRaces.items.map((item) => {
      if (item.stats?.RaceID?.slice(0, 6) === raceId?.slice(0, 6)) {
        if (!races[item.stats?.RaceID]) {
          races[item.stats?.RaceID] = {};
        }
        races[item.stats?.RaceID] = item.stats;
      }
      return item;
    });

    return races;
  }, [riderDetails?.riderResultsInRaces?.items]);

  return (
    <div className="flex relative flex-col px-10">
      <RiderPersonalDetails
        riderDetails={riderDetails}
      />
      <EventRacesResults
        eventId={raceId?.slice(0, 6)}
        eventRaces={currRace}
        riderId={riderId}
        riderWeight={riderDetails?.rider?.WeightKg}
      />
    </div>
  );
}
