import React from 'react';
import {
  Box, Button, Container, Header, LineChart as AWSLineChart,
} from '@awsui/components-react';
import './LineChart.css';

export default function LineChart(props) {
  let maxVal;
  let minVal;
  const lines = props.data.filter((d) => d.length > 0)
    .map((d) => ({
      title: d[0].title,
      type: 'line',
      data: d.map((item) => {
        if (!maxVal || item[props.yKey] > maxVal) maxVal = item[props.yKey];
        if (!minVal || item[props.yKey] < minVal) minVal = item[props.yKey];
        return {
          x: new Date(item.EventTimeStamp),
          y: item[props.yKey],
        };
      }),
    }));

  let offset = (maxVal - minVal) / 2;
  if (maxVal - minVal < 0) {
    offset *= -1;
  }
  if (offset === 0) {
    offset = 50;
  }
  const yMin = minVal - offset;
  const yMax = maxVal + offset;

  return (
    <div className="lineChartWrapper">
      <Container
        header={(
          <Header
            variant="h2"
            description=""
          >
            {props.label}
          </Header>
        )}
      >
        <AWSLineChart
          className="lineChart"
          series={[
            ...lines,
          ]}
          i18nStrings={{
            filterLabel: 'Filter displayed data',
            filterPlaceholder: 'Filter data',
            filterSelectedAriaLabel: 'selected',
            legendAriaLabel: 'Legend',
            chartAriaRoleDescription: 'line chart',
            xTickFormatter: (e) => e.toLocaleDateString('en-UK', {
              day: 'numeric',
              month: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: !1,
            })
              .split(',')
              .join('\n')
              + (e.getMilliseconds() > 0 ? `.${e.getMilliseconds()}` : ''),
          }}
          ariaLabel={props.label}
          errorText="Error loading data."
          height={300}
          loadingText="Loading chart"
          recoveryText="Retry"
          statusType="finished"
          xScaleType="time"
          yDomain={[
            yMin,
            yMax,
          ]}
          xTitle="Time"
          yTitle={props.yTitle}
          empty={(
            <Box textAlign="center">
              <b>No data available</b>
              <Box variant="p" color="inherit">
                There is no data available
              </Box>
            </Box>
          )}
          noMatch={(
            <Box textAlign="center">
              <b>No matching data</b>
              <Box variant="p" color="inherit">
                There is no data that matches the filter
              </Box>
              <Button>Clear filter</Button>
            </Box>
          )}
        />
      </Container>
    </div>
  );
}
