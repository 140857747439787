import './index.css';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Toggle } from '@awsui/components-react';

import Layout from '../../components/Layout';
import DataTable from '../../components/DataTable';
import LineChart from '../../components/LineChart';
import { RaceResult } from '../RaceResults/RaceResult';
import { useHookParam } from '../../hooks/useRouteParam';
import { apiActions } from '../../store/actions/api.actions';
import LineChartGraphana from '../../components/LineChartGraphana';
import { ContainerWithHeader } from '../../components/ContainerWithHeader';

const LAST_10_SECONDS_MS_FILTER = {
  value: 10000,
  label: 'Last 10 seconds',
};
const LAST_60_SECONDS_MS_FILTER = {
  value: 60000,
  label: 'Last minute',
};
const ENTIRE_RACE_FILTER = {
  value: 0,
  label: 'Entire race',
};
const timeFilterItems = [
  LAST_10_SECONDS_MS_FILTER,
  LAST_60_SECONDS_MS_FILTER,
  ENTIRE_RACE_FILTER,
];

export function RaceDashboard() {
  const dispatch = useDispatch();

  const liveData = useSelector((state) => state.api.liveData.current);
  const curRace = useSelector((state) => state.api.curRace.current);
  const latestTimeStamp = useSelector(
    (state) => state.api.latestTimeStamp.current,
  );

  const {
    raceId,
    eventId,
  } = useParams();

  // const [redZoneData, setRedZoneData] = useState([]);
  const [ridersData, setRidersData] = useState([]);
  const [ridersTracking, setRidersTracking] = useState([]);
  const [graphicTypeSelected, setGraphicTypeSelected] = useState(false);
  const [isLive, setIsLive] = useState(false);

  const [riderFilter, setRiderFilter] = useState([]);
  const [timeFilter, setTimeFilter] = useState(ENTIRE_RACE_FILTER.value);
  const [timeFilterDate, setTimeFilterDate] = useState(0);
  const [riderIds, setRiderIds] = useState([]);
  const polling = useSelector((state) => state.api.polling);

  const raceOperation = useSelector((state) => state.api.races);
  const eventsOperation = useSelector((state) => state.api.events);

  useEffect(() => {
    if (!eventsOperation?.current?.length) {
      dispatch(apiActions.getEvents());
    }
  }, [eventsOperation?.current, dispatch]);

  useEffect(() => {
    if (!raceOperation?.current?.length && eventId) {
      dispatch(apiActions.getRaces({ eventId }));
    }
  }, [eventId, raceOperation?.current, dispatch]);

  const { match: race } = useHookParam('raceId', raceOperation);
  const { match: event } = useHookParam('eventId', eventsOperation);
  const isRaceLive = race?.RaceStatus.toUpperCase() === 'LIVE';

  // eslint-disable-next-line consistent-return
  useMemo(() => {
    if (!race || !race.RaceStatus) {
      return false;
    }

    if (!graphicTypeSelected && isRaceLive) {
      if (isRaceLive) {
        dispatch(apiActions.stopPolling());
      }
    }
  }, [race]);

  const pageTitle = useMemo(() => {
    if (!race) {
      return undefined;
    }
    return `Results: ${race?.RaceName} - ${race?.League}`;
  }, [race]);

  const pageTitleBold = useMemo(() => {
    if (!event) {
      return undefined;
    }
    return ` ${event?.wordpress?.title}`;
  }, [event]);

  useEffect(() => {
    if (raceId !== null) {
      dispatch(apiActions.getRidersInRace({ raceId }));
    }
  }, [raceId]);

  useEffect(() => {
    if (raceId !== null && curRace) {
      const rIds = curRace.map((r) => r.UCIID);
      setRiderIds(rIds);
      dispatch(apiActions.clearLiveData());
      dispatch(
        apiActions.getLiveData({
          raceId,
          riderIds: rIds,
        }),
      );
    }
    return () => {
      dispatch(apiActions.stopPolling());
    };
  }, [raceId, curRace, dispatch]);

  useEffect(() => {
    if (timeFilter > ENTIRE_RACE_FILTER.value && latestTimeStamp) {
      setTimeFilterDate(new Date(latestTimeStamp.getTime() - timeFilter));
    } else {
      setTimeFilterDate(undefined);
    }
  }, [timeFilter, latestTimeStamp]);

  useEffect(() => {
    if (!liveData) return;

    const filterRecord = (item) => (timeFilterDate !== undefined
      ? new Date(item.EventTimeStamp) > timeFilterDate
      : true);

    const speedTracking = [];
    const otherData = [];
    const redZoneData = [];

    const curIds = riderFilter.length > 0 ? riderFilter : riderIds;

    Object.entries(liveData)
      .filter((entry) => entry[1].tracking || entry[1].data)
      .forEach((entry) => {
        if (!curIds.includes(entry[0])) return;
        const tracking = entry[1].tracking.filter((item) => filterRecord(item));
        const data = entry[1].data.filter((item) => filterRecord(item));

        if (!curRace) {
          return;
        }
        const rider = curRace.find((r) => r.UCIID === entry[0]);

        speedTracking.push(
          tracking.map((d) => ({
            ...d,
            title: `${d.UCIID} - ${rider.FirstName} ${rider.LastName}`,
            Speed: +d.Speed,
          })),
        );
        otherData.push(
          data.map((d) => ({
            ...d,
            title: `${d.UCIID} - ${rider.FirstName} ${rider.LastName}`,
            RiderCadency: +d.RiderCadency,
            RiderPower: +d.RiderPower,
            RiderHeartrate: +d.RiderHeartrate,
          })),
        );
        redZoneData.push(
          data
            .filter((d) => d.TimeSpentInRedZone)
            .map((d) => ({
              ...d,
              title: `${d.UCIID} - ${rider.FirstName} ${rider.LastName}`,
              TimeSpentInRedZone: +d.TimeSpentInRedZone,
            })),
        );
      });

    setRidersTracking(speedTracking);
    setRidersData(otherData);
    // setRedZoneData(redZoneData);
  }, [liveData, curRace, riderIds, riderFilter, timeFilterDate]);

  const resetFilter = () => {
    setRiderFilter([]);
    setTimeFilter(ENTIRE_RACE_FILTER);
  };

  const hasFilter = () => riderFilter.length !== 0 || timeFilter !== ENTIRE_RACE_FILTER;

  const renderFilter = () => {
    const timeItems = timeFilterItems.map((item) => (
      <Button
        key={item.value}
        disabled={timeFilter === item.value}
        onClick={() => {
          setTimeFilter(item.value);
        }}
      >
        {item.label}
      </Button>
    ));
    return (
      <div className="filterContainer mb-2">
        <div className="timeFilterContainer">
          <Button disabled={!hasFilter} onClick={resetFilter}>
            Reset filter
          </Button>
          {timeItems}
        </div>
        {/* <div className="riderFilterContainer">{riderItems}</div> */}
      </div>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function AwsCharts() {
    return (
      <div>
        <LineChart
          label="Speed over time"
          yTitle="Speed (km/h)"
          data={ridersTracking}
          yKey="Speed"
        />
        <LineChart
          label="Power over time"
          yTitle="Power (watts)"
          data={ridersData}
          yKey="RiderPower"
        />
        <LineChart
          label="Cadency over time"
          yTitle="Cadency"
          data={ridersData}
          yKey="RiderCadency"
        />
        <LineChart
          label="Heartrate over time"
          yTitle="Heartrate (bpm)"
          data={ridersData}
          yKey="RiderHeartrate"
        />
      </div>
    );
  }

  const exportData = (data, name) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data),
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${name}.json`;

    link.click();
  };

  function LocalCharts() {
    // eslint-disable-next-line no-shadow
    const dispatch = useDispatch();
    const uiSettings = useSelector((state) => state.api.settings);

    const [grafanaField, setGrafanaField] = useState(
      uiSettings?.current?.grafana,
    );
    const [speedField, setSpeedField] = useState(uiSettings?.current?.speedURL);
    const [cadenceField, setCadenceField] = useState(
      uiSettings?.current?.cadenceURL,
    );
    const [powerField, setPowerField] = useState(uiSettings?.current?.powerURL);
    const [hrField, setHrField] = useState(uiSettings?.current?.hrURL);

    // eslint-disable-next-line no-shadow
    const combineLink = (link, raceId) => {
      const regexHttp = /^\w+:.*?(:)\d*/g;
      const regex = /RaceID=.*&/gm;
      return link
        .replace(regexHttp, grafanaField)
        .replace(regex, `RaceID=${raceId}&`);
    };

    useEffect(() => {
      if (!uiSettings) dispatch(apiActions.getUISettings());
    }, [dispatch]);

    useEffect(() => {
      setGrafanaField(uiSettings?.current?.grafana);
      setSpeedField(uiSettings?.current?.speedURL);
      setCadenceField(uiSettings?.current?.cadenceURL);
      setPowerField(uiSettings?.current?.powerURL);
      setHrField(uiSettings?.current?.hrURL);
    }, [uiSettings?.current, dispatch]);

    return (
      <div>
        <LineChartGraphana
          label="Speed over time"
          yTitle="Speed (km/h)"
          url={combineLink(speedField, raceId)}
        />
        <LineChartGraphana
          label="Power over time"
          yTitle="Power (watts)"
          url={combineLink(powerField, raceId)}
        />
        <LineChartGraphana
          label="Cadency over time"
          yTitle="Cadency"
          url={combineLink(cadenceField, raceId)}
        />
        <LineChartGraphana
          label="Heartrate over time"
          yTitle="Heartrate (bpm)"
          url={combineLink(hrField, raceId)}
        />
      </div>
    );
  }

  function Table() {
    const curIds = riderFilter.length > 0 ? riderFilter : riderIds;
    const items = curIds.map((id) => {
      const riderInfo = curRace?.find((r) => r.UCIID === id);
      if (riderInfo) {
        const {
          FirstName,
          LastName,
        } = riderInfo;

        let RiderCadency;
        let RiderPower;
        let RiderHeartrate;
        let Speed;
        if (liveData && liveData[id]) {
          if (liveData[id].data.length > 0) {
            ({
              RiderCadency,
              RiderPower,
              RiderHeartrate,
            } = liveData[id].data[liveData[id].data.length - 1]);
          }
          if (liveData[id].tracking.length > 0) {
            ({ Speed } = liveData[id].tracking[liveData[id].tracking.length - 1]);
          }
        }

        return {
          UCIID: id,
          FirstName,
          LastName,
          RiderCadency,
          RiderPower,
          RiderHeartrate,
          Speed,
        };
      }
      return {};
    });

    return <DataTable items={items} />;
  }

  function Data() {
    return (
      <div className="dataWrapper">
        <Table />
        {isLive ? <LocalCharts /> : <AwsCharts />}
        <ContainerWithHeader label="Race Results">
          <RaceResult raceId={raceId} />
        </ContainerWithHeader>
      </div>
    );
  }

  return (
    <Layout
      title={pageTitle}
      titleBold={pageTitleBold}
      subtitle={race?.StartTime}
    >
      <div className="">
        <div className="flex flex-col">
          <h2 className="text-gray-dark text-2xl">Options</h2>
          <div className="flex flex-row h-12 items-center">
            <span className="w-40 capitalize">Graphic Type</span>
            <span className="flex flex-row">
              <span className="mr-2">AWS</span>
              <Toggle
                checked={isLive}
                onChange={({ detail }) => {
                  setGraphicTypeSelected(true);
                  if (detail.checked) {
                    dispatch(apiActions.stopPolling());
                  }
                  setIsLive(detail.checked);
                }}
              />
              <span className="ml-2">Local</span>
            </span>
          </div>
          {!isLive && (
            <div className="flex flex-row h-12 items-center">
              <span className="w-40 capitalize">Polling</span>
              <span className="flex flex-row">
                <span className="mr-2">Off</span>

                <Toggle
                  checked={polling}
                  onChange={({ detail }) => {
                    dispatch(
                      detail.checked
                        ? apiActions.setPolling()
                        : apiActions.stopPolling(),
                    );
                  }}
                />
                <span className="ml-2">On</span>
              </span>
            </div>
          )}
          {!isLive && curRace && (
            <div className="flex flex-row h-12 items-center">
              <span className="w-40 capitalize">Filter</span>
              <span className="flex flex-row">{renderFilter()}</span>
            </div>
          )}
        </div>
        <Data />
        <button
          type="button"
          className="btn awsui_button_vjswe_rqr8z_7 awsui_variant-normal_vjswe_rqr8z_27"
          onClick={() => exportData({
            data: ridersData,
            tracking: ridersTracking,
          }, `ridersDataRace${raceId}`)}
        >
          Export Data
        </button>
      </div>
    </Layout>
  );
}
