import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { Button } from '@awsui/components-react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useMemo, useEffect } from 'react';

import { RiderDetailsContent } from './RiderDetailsContent';
import { apiActions } from '../../store/actions/api.actions';

export function RiderInfo() {
  const dispatch = useDispatch();
  const params = useParams();

  const riderId = decodeURIComponent(params.riderId);
  const comparedRiderUciId = decodeURIComponent(params.comparedRiderUciId);

  const [riderComparisonView, setRiderComparisonView] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [comparedRiderId, setComparedRiderId] = useState({ value: comparedRiderUciId });

  useEffect(() => {
    if (comparedRiderId && comparedRiderId?.value !== 'undefined') {
      const newUrl = `${window.location.protocol}//${window.location.host.split('/')[0]}/rider/${riderId}/comparedRiderId/${comparedRiderId.value}`;
      window.history.replaceState(null, '', newUrl);
    }
  }, [comparedRiderId]);

  useEffect(() => {
    if (comparedRiderUciId !== 'undefined') {
      setRiderComparisonView(true);
      dispatch(apiActions.getComparedRiderDetails({ uciId: comparedRiderUciId }));
    }
  }, [comparedRiderUciId]);

  const riderListOperation = useSelector((state) => state.api.riders);
  const seasonsOperation = useSelector((state) => state.api.seasons);
  const riderDetailsOperation = useSelector(
    (state) => state.api.riderDetails[riderId],
  );
  const comparedRiderDetailsOperation = useSelector(
    (state) => state.api.comparedRiderDetails[comparedRiderId?.value],
  );
  const riderDetails = riderDetailsOperation?.current;
  const comparedRiderDetails = comparedRiderDetailsOperation?.current;

  const riderStatsOperation = useSelector(
    (state) => state.api.riderStats[{ riderId }],
  );
  const comparedRiderStatsOperation = useSelector(
    (state) => state.api.comparedRiderStats[{ comparedRiderId }],
  );
  const riderStats = riderStatsOperation?.current;
  const comparedRiderStats = comparedRiderStatsOperation?.current;

  const fetchRiderStats = (rId, activeTabId) => {
    if (rId?.length) {
      if (!activeTabId || activeTabId === '1') {
        dispatch(apiActions.getRiderStats({ uciId: rId }));
      } else {
        dispatch(apiActions.getRiderStats({ seasonId: activeTabId, uciId: rId }));
      }
    }
  };

  const fetchComparedRiderStats = (rId, activeTabId) => {
    if (rId?.length) {
      if (!activeTabId || activeTabId === '1') {
        dispatch(apiActions.getComparedRiderStats({ uciId: rId }));
      } else {
        dispatch(apiActions.getComparedRiderStats({ seasonId: activeTabId, uciId: rId }));
      }
    }
  };

  const toggleRiderComparisonView = () => {
    setRiderComparisonView((prev) => !prev);
  };

  const LayoutRiderComparisonButton = useMemo(() => (
    <Button
      className="btn h-8 ml-16"
      style={{ width: '104px' }}
      onClick={() => toggleRiderComparisonView()}
    >
      {riderComparisonView ? 'Hide Riders Comparison' : 'Compare Riders'}
    </Button>
  ), [riderComparisonView]);

  const seasons = useMemo(() => {
    if (!seasonsOperation?.current?.length) {
      return [];
    }
    return seasonsOperation?.current?.map?.((item) => ({
      value: item.seasonId,
      label: item.seasonId,
    }));
  }, [seasonsOperation]);

  const riders = useMemo(() => {
    if (!riderListOperation?.current?.items?.length) {
      return [];
    }
    return riderListOperation?.current?.items?.sort(
      (a, b) => a.Bib - b.Bib,
    ).map((item) => ({
      value: item.UCIID,
      label: `${item.Bib ? item.Bib : ''}    ${item.FirstName} ${item.LastName}    (${item.UCIID})`,
    }));
  }, [selectedSeason, riderListOperation]);

  useEffect(() => {
    if (riderId) {
      dispatch(apiActions.getRiderDetails({ uciId: riderId }));
    }
  }, [riderId]);

  useEffect(() => {
    dispatch(apiActions.getSeasons());
  }, [dispatch]);

  useEffect(() => {
    if (selectedSeason?.value) {
      dispatch(apiActions.getRidersList(selectedSeason?.value));
    }
  }, [selectedSeason]);

  useEffect(() => {
    if (riderComparisonView && comparedRiderId?.value?.length) {
      dispatch(apiActions.getComparedRiderDetails({ uciId: comparedRiderId?.value }));
    }
  }, [comparedRiderId]);

  return (
    <>
      <div className="container mt-5 w-full">
        <div className="flex flex-col justify-end align-bottom items-end">
          <div className="mb-5">
            {LayoutRiderComparisonButton}
          </div>
        </div>
        {riderComparisonView && (
          <div className="flex justify-end align-bottom items-end mt-5">
            <div className="w-1/15 mr-5">
              <Select
                options={seasons}
                onChange={setSelectedSeason}
                defaultValue={selectedSeason}
                placeholder="Select Season"
              />
            </div>
            <div className="w-1/4">
              <Select
                options={riders}
                onChange={setComparedRiderId}
                defaultValue={comparedRiderId}
                placeholder="Select Rider"
              />
            </div>
          </div>
        )}
      </div>
      <div className="w-100 h-full flex justify-between mt-5">
        <div className={riderComparisonView ? 'w-50' : 'w-100'}>
          <RiderDetailsContent
            riderId={riderId}
            hideBackButton={riderComparisonView}
            riderDetails={riderDetails}
            riderStats={riderStats}
            fetchRiderStats={fetchRiderStats}
          />
        </div>
        {riderComparisonView && comparedRiderDetails && (
          <div className="w-50">
            <RiderDetailsContent
              riderId={comparedRiderId?.value}
              riderDetails={comparedRiderDetails}
              riderDetailsOperation={comparedRiderDetailsOperation}
              riderComparisonView={riderComparisonView}
              riderStats={comparedRiderStats}
              fetchRiderStats={fetchComparedRiderStats}
            />
          </div>
        )}
      </div>
    </>
  );
}
