import { useEffect } from 'react';
import { Button } from '@awsui/components-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { apiActions } from '../../store/actions/api.actions';
import { SingleEventResultsContainer } from './SingleEventResultsContainer';

export function LayoutBackButton({ className }) {
  const location = useLocation();
  const history = useHistory();
  return (
    location.pathname !== '/' && (
      <Button
        className={`btn h-8 ml-16 ${className}`}
        style={{ width: '104px' }}
        onClick={() => {
          history.goBack();
        }}
      >
        &lt;&lt; Back
      </Button>
    )
  );
}

export function RidersRaceComparison() {
  const dispatch = useDispatch();
  const params = useParams();

  const raceId = decodeURIComponent(params.raceId);
  const rider1 = decodeURIComponent(params.rider1);
  const rider2 = decodeURIComponent(params.rider2);

  const riderDetailsOperation = useSelector(
    (state) => state.api.riderDetails[rider1],
  );
  const comparedRiderDetailsOperation = useSelector(
    (state) => state.api.comparedRiderDetails[rider2],
  );

  const rider1Details = riderDetailsOperation?.current;
  const rider2Details = comparedRiderDetailsOperation?.current;

  const fetchRiderStats = (rId, activeTabId) => {
    if (rId?.length) {
      dispatch(apiActions.getRiderStats({ seasonId: activeTabId, uciId: rId }));
    }
  };

  const fetchComparedRiderStats = (rId, activeTabId) => {
    if (rId?.length) {
      dispatch(apiActions.getComparedRiderStats({ seasonId: activeTabId, uciId: rId }));
    }
  };

  useEffect(() => {
    if (rider1) {
      dispatch(apiActions.getRiderDetails({ uciId: rider1 }));
    }
  }, [rider1]);

  useEffect(() => {
    if (rider2) {
      dispatch(apiActions.getComparedRiderDetails({ uciId: rider2 }));
    }
  }, [rider2]);

  useEffect(() => {
    if (raceId && rider1 && rider2) {
      fetchRiderStats(rider1, raceId.slice(0, 6));
      fetchComparedRiderStats(rider2, raceId.slice(0, 6));
    }
  }, [raceId, rider1, rider2]);

  return (
    <>
      <div className="container mt-5 w-full">
        <div className="flex flex-col justify-end align-bottom items-end">
          <div className="mb-5">
            <LayoutBackButton />
          </div>
        </div>
      </div>
      <div className="w-100 h-full flex justify-between mt-5">
        <div className="W-50">
          <SingleEventResultsContainer
            riderId={rider1}
            riderDetails={rider1Details}
            raceId={raceId}
            fetchRiderStats={fetchRiderStats}
          />
        </div>
        <div className="W-50">
          <SingleEventResultsContainer
            riderId={rider2}
            riderDetails={rider2Details}
            raceId={raceId}
            fetchRiderStats={fetchRiderStats}
          />
        </div>
      </div>
    </>
  );
}
