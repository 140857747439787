import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Alert, Spinner } from '@awsui/components-react';
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';

import './index.scss';
import Layout from '../../components/Layout';

const UserSettingsSchema = Yup.object()
  .shape({
    firstName: Yup.string()
      .min(2)
      .max(50)
      .required(),
    lastName: Yup.string()
      .min(2)
      .max(50)
      .required(),
    email: Yup.string()
      .email()
      .required(),
    oldPassword: Yup.string(),
    password: Yup.string(),
    confirmPassword: Yup.string()
      .test(
        'passwords-match',
        'Passwords must match',
        (value, form) => form.parent.password === value,
      ),
  });

export function UserSettings() {
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });

  const [successNotification, setSuccessNotification] = useState();
  const [errorNotification, setErrorNotification] = useState();

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();

      setInitialValues({
        firstName: user.attributes.name,
        lastName: user.attributes.family_name,
        email: user.attributes.email,
        oldPassword: '',
        password: '',
        confirmPassword: '',
      });
    })();
  }, []);

  return (
    <Layout title="Settings" className="relative">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={UserSettingsSchema}
        onSubmit={async (
          {
            firstName,
            lastName,
            email,
            oldPassword,
            password,
            confirmPassword,
          },
        ) => {
          try {
            let didUpdateDetails = false;
            let didUpdatePassword = false;
            const user = await Auth.currentAuthenticatedUser();

            await Auth.updateUserAttributes(user, {
              name: firstName,
              family_name: lastName,
              email,
            });
            didUpdateDetails = true;

            if (oldPassword && password && confirmPassword) {
              await Auth.changePassword(user, oldPassword, password);
              didUpdatePassword = true;
            }

            setSuccessNotification({
              didUpdateDetails,
              didUpdatePassword,
            });
            setErrorNotification(undefined);
          } catch (err) {
            setSuccessNotification({
              didUpdateDetails: false,
              didUpdatePassword: false,
            });
            setErrorNotification(err.message);
            console.error(
              'An error occurred while updating the user settings',
              err,
            );
          }
        }}
      >
        {({
          isValid,
          isSubmitting,
        }) => (
          <>
            {successNotification?.didUpdateDetails && (
              <Alert className="mb-4" visible type="success">
                Updated user details successfully
              </Alert>
            )}
            {successNotification?.didUpdatePassword && (
              <Alert className="mb-4" visible type="success">
                Updated password successfully
              </Alert>
            )}
            {errorNotification?.length && (
              <Alert className="mb-4" visible type="error">
                An error occurred:
                {' '}
                {errorNotification}
              </Alert>
            )}
            {isSubmitting && (
              <div
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                <Spinner />
              </div>
            )}

            <Form
              className={`flex flex-column ${
                isSubmitting ? 'opacity-50' : ''
              }`}
            >
              <div className="mb-2 flex h-16">
                <label htmlFor="firstName" className="w-96 font-weight-600">
                  First Name
                </label>
                <div className="flex flex-column">
                  <Field
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    className="h-9 px-2 w-64"
                  />
                  <span className="text-red">
                    <ErrorMessage name="firstName" />
                  </span>
                </div>
              </div>
              <div className="mb-2 flex h-16">
                <label htmlFor="lastName" className="w-96 font-weight-600">
                  Last Name
                </label>
                <div className="flex flex-column">
                  <Field
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    className="h-9 px-2 w-64"
                  />
                  <span className="text-red">
                    <ErrorMessage name="lastName" />
                  </span>
                </div>
              </div>
              <div className="mb-2 flex h-16">
                <label htmlFor="email" className="w-96 font-weight-600">
                  Email
                </label>
                <div className="flex flex-column">
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    className="h-9 px-2 w-64"
                  />
                  <span className="text-red">
                    <ErrorMessage name="email" />
                  </span>
                </div>
              </div>
              <div className="mb-2 flex h-16">
                <label htmlFor="oldPassword" className="w-96 font-weight-600">
                  Old Password
                </label>
                <div className="flex flex-column">
                  <Field
                    id="oldPassword"
                    name="oldPassword"
                    type="password"
                    placeholder="Old Password"
                    className="h-9 px-2 w-64"
                  />
                  <span className="text-red">
                    <ErrorMessage name="oldPassword" />
                  </span>
                </div>
              </div>
              <div className="mb-2 flex h-16">
                <label htmlFor="password" className="w-96 font-weight-600">
                  Password
                </label>
                <div className="flex flex-column">
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    className="h-9 px-2 w-64"
                  />
                  <span className="text-red">
                    <ErrorMessage name="password" />
                  </span>
                </div>
              </div>
              <div className="mb-2 flex h-16">
                <label
                  htmlFor="confirmPassword"
                  className="w-96 font-weight-600"
                >
                  Repeat Password
                </label>
                <div className="flex flex-column">
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                    className="h-9 px-2 w-64"
                  />
                  <span className="text-red">
                    <ErrorMessage name="confirmPassword" />
                  </span>
                </div>
              </div>
              <div className="ml-96">
                <button
                  type="submit"
                  className="py-1 px-3 bg-blue text-white font-weight-800 disabled:opacity-75"
                  disabled={!isValid || isSubmitting}
                >
                  Submit
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </Layout>
  );
}
