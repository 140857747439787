import React, { useEffect, useState } from 'react';

import { isAdminUser } from '../../services/user';
import CountryFlag from '../../components/CountryFlag';
import { LayoutBackButton } from '../../components/Layout';
import ImageWithFallback from '../../components/ImageWithFallback';

function Bib({ value }) {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    (async () => {
      setIsAdmin(await isAdminUser());
    })();
  }, []);

  return (
    isAdmin && (
      <>
        <br />
        BIB:
        {' '}
        {value}
      </>
    )
  );
}

export function RiderPersonalDetails({ riderDetails }) {
  const maxSpeed = riderDetails?.riderResultsInRaces?.items.reduce(
    (pv, cv) => Math.max(pv.RaceSpeed || 0, cv.RaceSpeed || 0),
    [],
  );

  return (
    <div className="flex flex-row flex-grow">
      <ImageWithFallback
        key={riderDetails?.rider?.wordpress?.portraitImageURIMedium}
        src={riderDetails?.rider?.wordpress?.portraitImageURIMedium}
        className="w-32 h-32 mr-8 mt-2"
      />
      <div className="flex flex-col flex-grow justify-between">
        <div className="grid grid-cols-3 gap-4 items-center mb-6">
          <h2 className="text-gray-dark text-2xl font-medium">
            {riderDetails?.rider?.FirstName}
            {' '}
            {riderDetails?.rider?.LastName}
            <hr />
            UCIID:
            {' '}
            {riderDetails?.rider?.UCIID}
            <Bib value={riderDetails?.rider?.Bib} />
          </h2>
          <div className="text-base leading-12 font-medium capitalize flex flex-row items-center">
            <CountryFlag countryName={riderDetails?.rider?.Nationality} />
            <span className="ml-12">{riderDetails?.rider?.LeagueCat}</span>
          </div>
          <div className="text-base leading-12 font-medium capitalize flex-grow">
            <div className="flex-grow" />
            <LayoutBackButton />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 items-center mb-2">
          <div className="text-sm">
            Height:
            {' '}
            {riderDetails?.rider?.HeightCm
              ? `${riderDetails?.rider.HeightCm} cm`
              : 'N/A'}
          </div>
          <div className="text-sm">
            Max Power:
            {' '}
            {riderDetails?.rider?.PowerPeakW || 'N/A'}
          </div>
          <div className="text-sm">
            Max HR:
            {' '}
            {riderDetails?.rider?.MaxHrBpm
              ? `${riderDetails?.rider.MaxHrBpm} bpm`
              : 'N/A'}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 items-center mb-2">
          <div className="text-sm">
            Weight:
            {' '}
            {riderDetails?.rider?.WeightKg
              ? `${riderDetails?.rider.WeightKg} kgs`
              : 'N/A'}
          </div>
          <div className="text-sm">Max Power TCL: N/A</div>
          <div className="text-sm">
            Resting HR:
            {' '}
            {riderDetails?.rider?.RestHrBpm
              ? `${riderDetails?.rider.RestHrBpm} bpm`
              : 'N/A'}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 items-center mb-2">
          <div className="text-sm capitalize">
            Age:
            {' '}
            {riderDetails?.rider?.wordpress?.age || 'N/A'}
          </div>
          <div className="text-sm">
            Max Speed TCL:
            {' '}
            {maxSpeed ? `${Number(maxSpeed)
              .toFixed(1)} kmh` : 'N/A'}
          </div>
          <div className="text-sm">
            Max HR TCL:
            {' '}
            {riderDetails?.rider?.MaxHrBpm
              ? `${riderDetails?.rider.MaxHrBpm} bpm`
              : 'N/A'}
          </div>
        </div>
      </div>
    </div>
  );
}
