import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function CountryFlag({ countryName }) {
  const countries = useSelector((state) => state.api.countries)?.current;

  const countryByName = useMemo(() => {
    if (!countries?.length) {
      return undefined;
    }
    return countries?.filter?.(
      ({ name }) => countryName?.toLocaleLowerCase() === name.common.toLocaleLowerCase(),
    )?.[0];
  }, [countryName, countries]);

  if (!countryByName?.flags?.png) {
    return (
      <div className="text-base leading-12 font-medium capitalize">
        {countryName}
      </div>
    );
  }

  return (
    <img
      src={countryByName.flags.png}
      alt="country flag"
      height="48"
      width="48"
    />
  );
}

export default CountryFlag;
