import gql from 'graphql-tag';

export default gql(`
query($uciId: String!) {
  getRiderConfidence(uciId: $uciId) {
    UCIID
    confidence {
      speed
      cadence
      heartrate
      power
    }
  }
}`);
