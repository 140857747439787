import React, { useEffect, useState, useMemo } from 'react';

import { NewGrid } from '../../components/Grid';
import { fuzzySort } from '../../utils/utils';

export function RiderRacesStats({ raceClassification }) {
  const [eventStats, setEventStats] = useState();

  function formatEventStats() {
    const formattedData = raceClassification.map((raceClass) => {
      const stats = {
        raceType: raceClass.RaceType,
        eventId: raceClass.EventID,
        seasonId: raceClass.SeasonID,
        points: raceClass.Points,
        rank: raceClass.Rank,
        starts: raceClass.raceStats.starts,
        wins: raceClass.raceStats.wins,
        winRate: raceClass.raceStats.winRate?.toFixed(2),
        top3: raceClass.raceStats.inTop3,
        top5: raceClass.raceStats.inTop5,
        top6: raceClass.raceStats.inTop6,
        bottom5: raceClass.raceStats.inBottom5,
        pointsRate: raceClass.races?.pointsRate?.toFixed(2) || '',
      };
      return stats;
    });
    return formattedData;
  }

  useEffect(() => {
    setEventStats(formatEventStats());
  }, [raceClassification]);

  const statsColumns = [
    {
      accessorFn: (row) => `${row.seasonId}`,
      id: 'seasonId',
      header: 'Season',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.eventId}`,
      id: 'eventId',
      header: 'Event ID',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.points}`,
      id: 'points',
      header: 'Points',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.rank}`,
      id: 'rank',
      header: 'Rank',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.pointsRate}`,
      id: 'pointsRate',
      header: 'Points Rate',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
  ];

  const memoizedContent = useMemo(() => (
    <div>
      <NewGrid
        data={eventStats}
        columns={statsColumns}
      />
    </div>
  ), [eventStats]);

  const tableHeading = useMemo(() => {
    let heading = raceClassification[0].RaceType;
    if (raceClassification[0].RaceType === 'General') {
      heading = 'Overall';
    }
    if (raceClassification[0].RaceType === 'League') {
      heading = 'Round';
    }

    return heading;
  }, [raceClassification[0].RaceType]);

  return raceClassification ? (
    <>
      <h1 className="mt-8 mb-4 font-semibold" style={{ fontSize: '1.5em' }}>
        { tableHeading }
      </h1>
      {memoizedContent}
    </>
  ) : null;
}
