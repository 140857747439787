import gql from 'graphql-tag';

export default gql(`query MyQuery($seasonId: String!) {
  getSeasonStandings(seasonId: $seasonId) {
    League
    Standings {
      NOC
      Message
      ShortTVName
      League
      Team
      Gender
      SeasonID
      UCIID
      Bib
      FirstName
      LastName
      PointsTotal
      Rank
    }
  }
}
`);
