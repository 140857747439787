import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export function HeaderLink({
  name,
  path,
}) {
  const location = useLocation();

  const className = `navItem ${
    location.pathname === path ? 'active' : ''
  }`.trimEnd();

  return (
    <Link className={className} to={path}>
      {name}
    </Link>
  );
}
