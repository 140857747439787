import React from 'react';
import { Box, Button } from '@awsui/components-react';

export function Error404() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box className="lightText" variant="h1">
        Error 404
      </Box>
      <Box className="lightText" variant="h2">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        It seems like we couldn't find the page you were looking for
      </Box>
      <Button href="/">Return to Home Page</Button>
    </div>
  );
}
