import React from 'react';

import Layout from '../../components/Layout';
import { RiderDataConfidence } from './RiderDataConfidence';
import { RiderPersonalDetails } from './RiderPersonalDetails';
import { EventRacesResultsContainer } from './EventRacesResultsContainer';

export function RiderDetailsContent({
  riderId,
  riderDetails,
  riderDetailsOperation,
  riderStats,
  fetchRiderStats,
  hideBackButton,
}) {
  return (
    <Layout
      title="Rider Info"
      operation={riderDetailsOperation}
      hide={{ header: true }}
      hideBackButton={hideBackButton}
    >
      <RiderPersonalDetails
        riderDetails={riderDetails}
      />
      <EventRacesResultsContainer
        riderId={riderId}
        riderDetails={riderDetails}
        riderStats={riderStats}
        fetchRiderStats={fetchRiderStats}
      />
      <RiderDataConfidence
        title="Rider data confidence"
        riderId={riderId}
        riderConfidence={riderDetails?.confidence}
      />
    </Layout>
  );
}
