import React, { useEffect, useState } from 'react';

import { HeaderLink } from './HeaderLink';
import { isAdminUser } from '../../services/user';

const md5 = require('md5');

const headerLinks = [
  {
    path: '/',
    name: 'Events',
    isAdmin: false,
  },
  {
    path: '/riders',
    name: 'Riders',
    isAdmin: false,
  },
  {
    path: '/data-confidence',
    name: 'Settings',
    isAdmin: true,
  },
  {
    path: '/standings',
    name: 'Standings',
    isAdmin: true,
  },
  // { path: "/cam-settings", name: "Cam settings", isAdmin: true },
];

export const HeaderLinks = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    (async () => {
      setIsAdmin(await isAdminUser());
    })();
  }, []);

  const filteredHeaderLinks = headerLinks.filter(
    (item) => !item.isAdmin || item.isAdmin === isAdmin,
  );

  return filteredHeaderLinks.map((item, i) => <HeaderLink key={md5(`link${i}`)} {...item} />);
};
