import React from 'react';

import { fuzzySort } from '../../utils/utils';

export const useColumns = () => {
  const getMaxRaceRiderPower = ({ row }) => {
    if (!row.original?.data?.MaxRaceRiderPower) {
      return '';
    }
    if (Number(row.original?.data?.MaxRaceRiderPower) > Number(row.original?.profile?.PowerPeakW)) {
      return (
        <div className="alert alert-danger" role="alert">
          <p>
            <b>{`New: ${row.original?.data?.MaxRaceRiderPower}`}</b>
          </p>
          <p>
            {`Old:
            ${' '}
            ${row.original?.profile?.PowerPeakW}`}
          </p>
        </div>
      );
    }
    return row.original?.data?.MaxRaceRiderPower || '';
  };
  const getPercentMaxPower = ({ row }) => {
    if (row.original?.data?.MaxRaceRiderPower && row.original?.profile?.PowerPeakW) {
      if (!Number.isNaN(row.original?.data?.MaxRaceRiderPower)
        && !Number.isNaN(row.original?.profile?.PowerPeakW)) {
        return ((Number(row.original?.data?.MaxRaceRiderPower)
            / Number(row.original?.profile?.PowerPeakW))
          * 100
        ).toFixed(1) ? ((Number(row.original?.data?.MaxRaceRiderPower)
          / Number(row.original?.profile?.PowerPeakW))
          * 100
          ).toFixed(1) : '';
      }
    }
    return '';
  };
  const getMaxRaceRiderHeartRate = ({ row }) => {
    if (Number(row.original?.data?.MaxRaceRiderHeartrate)
      > Number(row.original?.profile?.MaxHrBpm)) {
      return (
        <div className="alert alert-danger" role="alert">
          <p><b>{`New: ${row.original?.data?.MaxRaceRiderHeartrate}`}</b></p>
          <p>
            {`Old: ${row.original?.profile?.MaxHrBpm}`}
          </p>

        </div>
      );
    }
    return row.original?.data?.MaxRaceRiderHeartrate || '';
  };

  const columns = React.useMemo(() => [
    {
      accessorFn: (row) => `${row.profile?.Bib}`,
      id: 'Bib',
      header: 'Bib',
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '',
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.profile?.UCIID}`,
      id: 'UCIID',
      header: 'UCI ID',
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '',
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.profile?.FirstName} ${row.profile?.LastName}`,
      id: 'Name',
      header: 'Rider Name',
      footer: (props) => props.column.id,
      cell: (info) => info.getValue() || '',
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.data?.RiderPowerMeterAverage}`,
      id: 'RiderPowerMeterAverage',
      header: 'Current Power',
      footer: (props) => props.column.id,
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.data?.MaxRaceRiderPower} ${row.profile?.PowerPeakW}`,
      id: 'Race Max Power',
      header: 'Race Max Power',
      cell: getMaxRaceRiderPower,
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.data?.MaxRaceRiderPower} ${row.profile?.PowerPeakW}`,
      id: '% Max Power',
      header: '% Max Power',
      cell: getPercentMaxPower,
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.data?.RiderHeartrate}`,
      id: 'RiderHeartrate',
      header: 'Current HR',
      footer: (props) => props.column.id,
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.data?.MaxRaceRiderHeartrate} ${row.profile?.MaxHrBpm}`,
      id: 'RaceMaxHR',
      header: 'Race Max HR',
      cell: getMaxRaceRiderHeartRate,
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.data?.RiderSpeed}`,
      id: 'CurrentSpeed',
      header: 'Current Speed',
      footer: (props) => props.column.id,
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.data?.MaxRaceRiderSpeed}`,
      id: 'RaceMaxSpeed',
      header: 'Race Max Speed',
      footer: (props) => props.column.id,
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.data?.RiderCadency}`,
      id: 'CurrentCadence',
      header: 'Current Cadence',
      footer: (props) => props.column.id,
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.data?.MaxRaceRiderCadency}`,
      id: 'RaceMaxCadence',
      header: 'Race Max Cadence',
      footer: (props) => props.column.id,
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
  ], []);

  return columns;
};
