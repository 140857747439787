import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';

import { fuzzySort } from '../../utils/utils';
import { NewGrid } from '../../components/Grid';
import { isAdminUser } from '../../services/user';
import { apiActions } from '../../store/actions/api.actions';

function matchRiders(raceResults, riderList, raceResultsStats) {
  const riderListMatch = raceResults?.items?.map?.((raceResult, i) => {
    let matchingRider = riderList?.items?.filter?.(
      (riderInfo) => riderInfo.UCIID === raceResult.UCIID,
    )?.[0];
    matchingRider = _.omitBy(matchingRider, _.isNil);
    return {
      ...raceResult,
      Starting: i + 1,
      PowerPeakW: matchingRider?.PowerPeakW,
      Weight: matchingRider?.WeightKg,
      MaxHR: matchingRider?.MaxHrBpm,
      RiderName: matchingRider
        ? `${matchingRider.FirstName} ${matchingRider.LastName}`
        : '',
    };
  }) || [];
  const final = riderListMatch.map((raceResult) => {
    // eslint-disable-next-line max-len
    let matchingRider = raceResultsStats?.filter?.((riderInfo) => riderInfo.UCIID === raceResult.UCIID)?.[0];
    matchingRider = _.omitBy(matchingRider, _.isNil);

    return {
      ...raceResult,
      MaxRaceRiderCadency: matchingRider?.MaxRaceRiderCadency,
      MaxRaceRiderPower: matchingRider?.MaxRaceRiderPower,
      MaxRaceRiderSpeed: matchingRider?.MaxRaceRiderSpeed,
      MaxRaceRiderHR: matchingRider?.MaxRaceRiderHeartrate,
      AvgRaceRiderCadency: matchingRider?.AvgRaceRiderCadency,
      AvgRaceRiderPower: matchingRider?.AvgRaceRiderPower,
      AvgRaceRiderSpeed: matchingRider?.AvgRaceRiderSpeed,
      AvgRaceRiderHR: matchingRider?.AvgRaceRiderHeartrate,
    };
  });

  return final.sort((a, b) => a.Rank - b.Rank);
}

const getMaxRaceRiderHRFormat = ({ row }) => {
  if (row.original?.MaxRaceRiderHR && row.original?.MaxHR) {
    if (Number(row.original?.MaxRaceRiderHR) > Number(row.original?.MaxHR)) {
      return (
        <div className="alert alert-danger" role="alert">
          New Record:
          {' '}
          {row.original?.MaxRaceRiderHR}
          {' '}
          <br />
          Old:
          {' '}
          {row.original?.MaxHR}
        </div>
      );
    }
  }
  return row.original?.MaxRaceRiderHR || row.original?.MaxHR || '';
};

const getMaxRaceRiderPowerFormat = ({ row }) => {
  if (row.original?.MaxRaceRiderPower && row.original?.PowerPeakW) {
    if (Number(row.original?.MaxRaceRiderPower) > Number(row.original?.PowerPeakW)) {
      return (
        <div className="alert alert-danger" role="alert">
          New Record:
          {' '}
          {row.original?.MaxRaceRiderPower}
          {' '}
          <br />
          Old:
          {' '}
          {row.original?.PowerPeakW}
        </div>
      );
    }
  }
  return row.original?.MaxRaceRiderPower || row.original?.PowerPeakW || '';
};

export function RaceResult({
  raceId,
}) {
  const dispatch = useDispatch();
  const raceResultsOperation = useSelector((state) => state.api.raceResults);

  const raceResultsStatsOperation = useSelector(
    (state) => state.api.raceResultsStats,
  );
  const raceResults = raceResultsOperation?.[raceId]?.current;
  const raceResultsStats = raceResultsStatsOperation?.[raceId]?.current;
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    (async () => {
      setIsAdmin(await isAdminUser());
    })();
  }, []);

  useEffect(() => {
    if (!raceResults) {
      dispatch(apiActions.getRaceResults({ raceId }));
    }
  }, [raceId, raceResults]);

  useEffect(() => {
    if (!raceResultsStats) {
      dispatch(apiActions.getRaceResultsStats({ raceId }));
    }
  }, [raceResults, raceId, raceResultsStats]);

  useEffect(() => {
    dispatch(apiActions.stopLiveRiderDataPolling());
  }, [dispatch]);

  const riderListOperation = useSelector((state) => state.api.riders);

  useEffect(() => {
    if (!riderListOperation.current) dispatch(apiActions.getRidersList(raceId.substring(0, 4)));
  }, [dispatch, raceId]);

  const resultsRiderJoinResults = useMemo(() => {
    const riderList = riderListOperation?.current;
    return matchRiders(raceResults, riderList, raceResultsStats);
  }, [
    raceResults?.items,
    riderListOperation,
    isAdmin,
    raceResultsStatsOperation,
  ]);

  const columns = React.useMemo(() => [
    {
      accessorFn: (row) => `${row.Rank}`,
      id: 'Rank',
      header: 'Rank',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.Bib}`,
      id: 'Bib',
      header: 'Bib',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.UCIID}`,
      id: 'UCIID',
      header: 'UCI ID',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.FirstName} ${row.LastName}`,
      id: 'RiderName',
      header: 'Rider Name',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.AvgRaceRiderPower}`,
      id: 'AvgRaceRiderPower',
      header: 'Avg Race Power',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.MaxRaceRiderPower}`,
      id: 'MaxRaceRiderPower',
      header: 'Max Race Power',
      cell: getMaxRaceRiderPowerFormat,
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.AvgRaceRiderSpeed}`,
      id: 'AvgRaceRiderSpeed',
      header: 'Avg Race Speed',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.MaxRaceRiderSpeed}`,
      id: 'MaxRaceRiderSpeed',
      header: 'Max Race Speed',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.AvgRaceRiderCadency}`,
      id: 'AvgRaceRiderCadence',
      header: 'Avg Race Cadence',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.MaxRaceRiderCadency}`,
      id: 'MaxRaceRiderCadence',
      header: 'Max Race Cadence',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.AvgRaceRiderHR}`,
      id: 'AvgRaceRiderHR',
      header: 'Avg Race HR',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'undefined') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.MaxRaceRiderHR}`,
      id: 'MaxRaceRiderHR',
      header: 'Max Race HR',
      cell: getMaxRaceRiderHRFormat,
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
  ], []);

  return (
    <div>
      <div className="py-5">
        {resultsRiderJoinResults && (
          <NewGrid
            data={resultsRiderJoinResults}
            columns={columns}
            displayAllRows
          />
        )}
      </div>
    </div>
  );
}
