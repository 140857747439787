import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Toggle } from '@awsui/components-react';

import { isAdminUser } from '../../services/user';
import { apiActions } from '../../store/actions/api.actions';

const sensorDataTypes = ['speed', 'power', 'cadence', 'heart rate'];

const defaultConfidence = {
  speed: true,
  power: true,
  cadence: true,
  'heart rate': true,
};

export function RiderDataConfidence({
  title,
  riderId,
  riderConfidence,
}) {
  const dispatch = useDispatch();
  const [isAdmin, setIsAdmin] = useState(false);
  const confidence = riderConfidence || defaultConfidence;

  useEffect(() => {
    (async () => {
      setIsAdmin(await isAdminUser());
    })();
  }, []);

  if (!isAdmin) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <div className="mt-12 flex flex-col">
      <h2 className="text-gray-dark text-2xl">{title}</h2>

      {sensorDataTypes.map((key) => {
        const keyFormatted = key.replace(' ', '');

        return (
          <div className="flex flex-row h-12 items-center">
            <span className="w-40 capitalize">{key}</span>
            <span className="flex flex-row">
              <span className="mr-2">Low confidence</span>

              <Toggle
                checked={confidence[keyFormatted]}
                onChange={({ detail }) => {
                  confidence[keyFormatted] = detail.checked;
                  dispatch(
                    apiActions.toggleRiderConfidence({
                      confidence,
                      uciId: riderId,
                    }),
                  );
                }}
              />

              <span className="ml-2">High confidence</span>
            </span>
          </div>
        );
      })}
    </div>
  );
}
