import { Button } from '@awsui/components-react';
import React, { useEffect, useMemo } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import Layout from '../../components/Layout';
import { fuzzySort } from '../../utils/utils';
import { useHookParam } from '../../hooks/useRouteParam';
import { apiActions } from '../../store/actions/api.actions';
import { IndeterminateCheckbox, NewGrid } from '../../components/Grid';

export function RiderComparisonButton({ row, raceId }) {
  const history = useHistory();
  const notify = (text) => toast.error(text);
  return (
    <Button
      className="btn h-8 ml-16"
      style={{ width: '104px' }}
      onClick={() => {
        if (row?.getSelectedRowModel().rows?.length === 2) {
          const rider1 = row?.getSelectedRowModel().rows[0]?.original.UCIID;
          const rider2 = row?.getSelectedRowModel().rows[1]?.original.UCIID;
          history.push(`/race/${raceId}/ridersComparison/${encodeURIComponent(rider1)}/${encodeURIComponent(rider2)}`);
        } else {
          notify('Please select two riders!');
        }
      }}
    >
      Compare
    </Button>
  );
}

export function RaceStartList() {
  const dispatch = useDispatch();

  const {
    raceId,
    eventId,
  } = useParams();
  const raceOperation = useSelector((state) => state.api.races);
  const currentRaceOperation = useSelector((state) => state.api.curRace);

  const { match: race } = useHookParam('raceId', raceOperation);

  const seasonId = eventId.substring(0, 4);
  const startList = currentRaceOperation?.current;

  useEffect(() => {
    if (raceId?.length) {
      dispatch(apiActions.getRidersInRace({ raceId }));
    }
  }, [race, raceId]);

  const riderListOperation = useSelector((state) => state.api.riders);

  useEffect(() => {
    if (!riderListOperation?.current?.items?.length) {
      dispatch(apiActions.getRidersList(seasonId));
    }
  }, [riderListOperation?.current?.items?.length, raceId]);

  const pageTitle = useMemo(() => {
    if (!race) {
      return undefined;
    }
    return `Start List - ${race?.League} - ${race?.RaceID}`;
  }, [race]);

  const pageTitleBold = useMemo(() => {
    if (!race) {
      return undefined;
    }
    return ` ${race?.RaceName}`;
  }, [race]);

  const startRiderListResults = useMemo(() => {
    const riderList = riderListOperation?.current;

    return (
      startList?.map?.((startListRider, i) => {
        const matchingRider = riderList?.items?.filter?.(
          (riderInfo) => riderInfo.UCIID === startListRider.UCIID,
        )?.[0];

        return {
          ...startListRider,
          Starting: i + 1,
          MaxPower: matchingRider?.PowerPeakW,
          Weight: matchingRider?.WeightKg,
          MaxHR: matchingRider?.MaxHrBpm,
          RestHr: matchingRider?.RestHrBpm,
          Bib: matchingRider?.Bib,
          SeasonTitle: matchingRider?.SeasonTitle,
          Nationality: matchingRider?.Nationality,
          LeagueCat: matchingRider?.LeagueCat,
          Gender: matchingRider?.Gender,
        };
      }) || []
    );
  }, [startList, riderListOperation]);

  const columns = React.useMemo(() => [
    {
      accessorFn: (row) => `${row.Bib}`,
      id: 'Bib',
      header: 'Bib',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.UCIID}`,
      id: 'UCIID',
      header: 'UCI ID',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.FirstName} ${row.LastName}`,
      id: 'Name',
      header: 'Rider Name',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.MaxPower}`,
      id: 'Race Max Power',
      header: 'Race Max Power',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.Weight}`,
      id: 'Weight',
      header: 'Weight',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.MaxHR}`,
      id: 'MaxHR',
      header: 'Max HR',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.RestHR || ''}`,
      id: 'RestHR',
      header: 'Resting HR',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.Nationality}`,
      id: 'Nationality',
      header: 'Nationality',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.LeagueCat}`,
      id: 'LeagueCat',
      header: 'LeagueCat',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.Gender}`,
      id: 'Gender',
      header: 'Gender',
      cell: (info) => info.getValue() || '',
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      id: 'select',
      header: ({ table }) => (
        <RiderComparisonButton row={table} raceId={raceId} />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
  ], [startRiderListResults]);

  return (
    <Layout
      title={pageTitle}
      titleBold={pageTitleBold}
      subtitle={race?.StartTime}
      operation={raceOperation}
    >
      {/* {errorMessage !== '' && (
        <div className="text-right w-full text-red">{errorMessage}</div>
      )} */}
      <NewGrid
        data={startRiderListResults || []}
        columns={columns}
        displayAllRows
        isRowSelectionEnabled
      />
      <ToastContainer position="bottom-right" />
    </Layout>
  );
}
