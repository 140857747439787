export const getLoadingStatusInitial = () => ({
  didSucceed: false,
  isLoading: false,
});

export const getLoadingStatusInProgress = () => ({
  didSucceed: false,
  isLoading: true,
});

export const getLoadingStatusSuccess = () => ({
  didSucceed: true,
  isLoading: false,
});

export const getLoadingStatusError = (error) => ({
  didSucceed: false,
  error,
  isLoading: false,
});

export const getStateInitial = (initialValue = undefined) => ({
  current: initialValue,
  status: getLoadingStatusInitial(),
});

export const getStateInProgress = () => ({
  current: undefined,
  status: getLoadingStatusInProgress(),
});
export const getStateSuccess = (current) => ({
  current,
  status: getLoadingStatusSuccess(),
});

export const getStateError = (error) => ({
  current: undefined,
  status: getLoadingStatusError(error),
});
