import { combineReducers } from 'redux';

import { apiReducers, initialAPIState } from './api.reducer';

export const rootReducers = combineReducers({
  api: apiReducers,
});

export const initialRootState = {
  ...initialAPIState,
};
