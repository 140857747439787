import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import { fuzzySort } from '../../utils/utils';
import { NewGrid } from '../../components/Grid';

export function EventRacesResults({
  eventId,
  eventRaces,
  riderWeight,
}) {
  const [data, setData] = useState();

  const formatRaceResults = useCallback(() => {
    const results = Object.keys(eventRaces)
      .map((keyRaceId) => {
        const currentRace = eventRaces[keyRaceId];
        if (currentRace) {
          const avgP2W = ((currentRace.AvgRaceRiderPower / riderWeight))?.toFixed(2);
          const maxP2W = ((currentRace.MaxRaceRiderPower / riderWeight))?.toFixed(2);
          const stats = {
            raceId: currentRace.RaceID,
            raceName: currentRace.RaceName,
            AvgRaceRiderHeartrate: currentRace.AvgRaceRiderHeartrate,
            AvgRaceRiderSpeed: currentRace.AvgRaceRiderSpeed,
            AvgRaceRiderCadence: currentRace.AvgRaceRiderCadency,
            AvgRaceRiderPower: currentRace.AvgRaceRiderPower,
            MaxRaceRiderCadence: currentRace.MaxRaceRiderCadency,
            MaxRaceRiderHeartrate: currentRace.MaxRaceRiderHeartrate,
            MaxRaceRiderSpeed: currentRace.MaxRaceRiderSpeed,
            MaxRaceRiderPower: currentRace.MaxRaceRiderPower,
            MaxRaceSpeed: currentRace.MaxRaceSpeed,
            MaxRaceHeartrate: currentRace.MaxRaceHeartrate,
            AvgPowToWeiRatio: avgP2W !== 'NaN' ? avgP2W : '',
            MaxPowToWeiRatio: maxP2W !== 'NaN' ? maxP2W : '',
          };
          return stats;
        }
        return {
          raceId: '',
          raceName: '',
          AvgRaceRiderHeartrate: '',
          AvgRaceRiderSpeed: '',
          AvgRaceRiderCadence: '',
          AvgRaceRiderPower: '',
          MaxRaceRiderCadence: '',
          MaxRaceRiderHeartrate: '',
          MaxRaceRiderSpeed: '',
          MaxRaceRiderPower: '',
          MaxRaceSpeed: '',
          MaxRaceHeartrate: '',
          AvgPowToWeiRatio: '',
          MaxPowToWeiRatio: '',
        };
      });
    return results;
  });

  useEffect(() => {
    setData(formatRaceResults());
  }, [eventRaces]);

  const statsColumns = [
    {
      accessorFn: (row) => `${row.raceId}`,
      id: 'raceId',
      header: 'Race ID',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'null') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    // {
    //   accessorFn: (row) => `${row.raceName}`,
    //   id: 'raceName',
    //   header: 'Race Name',
    //   footer: (props) => props.column.id,
    //   filterFn: 'fuzzy',
    //   sortingFn: fuzzySort,
    //   size: 2,
    // },
    {
      accessorFn: (row) => `${row.AvgRaceRiderHeartrate}`,
      id: 'AvgRaceRiderHeartrate',
      header: 'Avg HR',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'null') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.MaxRaceRiderHeartrate}`,
      id: 'MaxRaceRiderHeartrate',
      header: 'Max HR',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'null') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.AvgRaceRiderSpeed}`,
      id: 'AvgRaceRiderSpeed',
      header: 'Avg Speed',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'null') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.MaxRaceRiderSpeed}`,
      id: 'MaxRaceRiderSpeed',
      header: 'Max Speed',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'null') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.AvgRaceRiderCadence}`,
      id: 'AvgRaceRiderCadence',
      header: 'Avg Cadence',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'null') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.MaxRaceRiderCadence}`,
      id: 'MaxRaceRiderCadence',
      header: 'Max Cadence',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'null') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.AvgRaceRiderPower}`,
      id: 'AvgRaceRiderPower',
      header: 'Avg Power',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'null') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.MaxRaceRiderPower}`,
      id: 'MaxRaceRiderPower',
      header: 'Max Power',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'null') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.AvgPowToWeiRatio}`,
      id: 'AvgPowToWeiRatio',
      header: 'Avg Pow/KG',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'Infinity') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
    {
      accessorFn: (row) => `${row.MaxPowToWeiRatio}`,
      id: 'MaxPowToWeiRatio',
      header: 'Max Pow/KG',
      cell: (info) => {
        if (info.getValue() && info.getValue() !== 'Infinity') {
          return info.getValue();
        }
        return '';
      },
      footer: (props) => props.column.id,
      filterFn: 'fuzzy',
      sortingFn: fuzzySort,
      size: 2,
    },
  ];

  return (
    <div key={`EventRaceResults_${eventId}`} className="mt-6 overflow-x-scroll">
      <div className="mb-4 mt-4">
        <div className="text-gray-dark text-xl font-extrabold">
          Event ID:&nbsp;
          {eventId}
        </div>
        <div>
          <NewGrid
            data={data}
            columns={statsColumns}
          />
        </div>
      </div>
    </div>
  );
}
