import gql from 'graphql-tag';

export default gql(`
query {
  getEvents {
    eventId
    eventName
    wordpress {
      id
      logoImageURI
      trackMapImageURI  
      startDate
      title
    }
  }
}`);
