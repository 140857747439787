import React, { useEffect, useState } from 'react';
import { Button } from '@awsui/components-react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { toast, ToastContainer } from 'react-toastify';
import Layout from '../../components/Layout';
import { apiActions } from '../../store/actions/api.actions';

const defaultConfidence = {
  speed: true,
  power: true,
  cadence: true,
  heartrate: true,
};

const useUpdateAllRidersConfidence = () => {
  const dispatch = useDispatch();

  const riderListOperation = useSelector((state) => state.api.riders);

  useEffect(() => {
    if (!riderListOperation?.current?.items?.length) {
      dispatch(apiActions.getRidersList());
    }
  }, [riderListOperation?.current?.items?.length]);

  const riderList = riderListOperation?.current?.items || [];

  return ({
    confidenceKey,
    checked,
  }) => {
    // eslint-disable-next-line no-restricted-syntax
    for (let {
      // eslint-disable-next-line prefer-const
      UCIID,
      Confidence,
    } of riderList) {
      Confidence = Confidence || defaultConfidence;

      Confidence[confidenceKey] = checked;

      console.log(confidenceKey, checked, UCIID);

      dispatch(
        apiActions.toggleRiderConfidence({
          confidence: Confidence,
          uciId: UCIID,
        }),
      );
    }
  };
};

export function DataConfidence() {
  const dispatch = useDispatch();
  const uiSettings = useSelector((state) => state.api.settings);
  const [grafanaField, setGrafanaField] = useState(uiSettings);
  const [speedField, setSpeedField] = useState(uiSettings);
  const [cadenceField, setCadenceField] = useState(uiSettings);
  const [powerField, setPowerField] = useState(uiSettings);
  const [hrField, setHrField] = useState(uiSettings);

  useEffect(() => {
    if (!uiSettings) dispatch(apiActions.getUISettings());
  }, [dispatch]);

  useEffect(() => {
    setGrafanaField(uiSettings?.current?.grafana);
    setSpeedField(uiSettings?.current?.speedURL);
    setCadenceField(uiSettings?.current?.cadenceURL);
    setPowerField(uiSettings?.current?.powerURL);
    setHrField(uiSettings?.current?.hrURL);
  }, [uiSettings?.current?.grafana]);

  const updateAllRidersConfidence = useUpdateAllRidersConfidence();
  const notify = (text) => toast.success(text);

  const handleChangeHeartRate = (value) => {
    updateAllRidersConfidence({
      confidenceKey: 'heartrate',
      checked: value,
    });
    notify(
      `HR settings set to ${value ? 'high confidence' : 'low confidence'}`,
    );
  };

  const saveUISettings = (event) => {
    dispatch(apiActions.setUISettings({
      grafana: grafanaField,
      speedURL: speedField,
      powerURL: powerField,
      hrURL: hrField,
      cadenceURL: cadenceField,
    }));
    notify(
      'Saved',
    );
    event.preventDefault();
  };

  const handleGrafanaInput = (event) => {
    setGrafanaField(event.target.value);
  };

  const handlePowerInput = (event) => {
    setPowerField(event.target.value);
  };

  const handleCadenceInput = (event) => {
    setCadenceField(event.target.value);
  };

  const handleSpeedInput = (event) => {
    setSpeedField(event.target.value);
  };

  const handleHRInput = (event) => {
    setHrField(event.target.value);
  };

  const handleChangePower = (value) => {
    updateAllRidersConfidence({
      confidenceKey: 'power',
      checked: value,
    });
    notify(
      `Power settings set to ${value ? 'high confidence' : 'low confidence'}`,
    );
  };

  const handleChangeCadence = (value) => {
    updateAllRidersConfidence({
      confidenceKey: 'cadence',
      checked: value,
    });
    notify(
      `Cadence settings set to ${
        value ? 'high confidence' : 'low confidence'}`,
    );
  };

  const handleChangeSpeed = (value) => {
    updateAllRidersConfidence({
      confidenceKey: 'speed',
      checked: value,
    });
    notify(
      `Speed settings set to ${value ? 'high confidence' : 'low confidence'}`,
    );
  };

  const pageTitle = 'Data Confidence settings';
  return (
    <Layout title={pageTitle}>
      <div className="mt-12 ">
        <div className="row">
          <div className="card col m-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <Button
                  className="btn mr-3 my-3"
                  onClick={() => handleChangeCadence(true)}
                >
                  Cadence to High Confidence
                </Button>
              </li>
              <li className="list-group-item">
                <Button
                  className="btn mr-3 my-3"
                  onClick={() => handleChangeCadence(false)}
                >
                  Cadence to Low Confidence
                </Button>
              </li>
            </ul>
          </div>
          <div className="card col m-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <Button
                  className="btn mr-3 my-3"
                  onClick={() => handleChangeSpeed(true)}
                >
                  Speed to High Confidence
                </Button>
              </li>
              <li className="list-group-item">
                <Button
                  className="btn mr-3 my-3"
                  onClick={() => handleChangeSpeed(false)}
                >
                  Speed to Low Confidence
                </Button>
              </li>
            </ul>
          </div>
          <div className="card col m-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <Button
                  className="btn mr-3 my-3"
                  onClick={() => handleChangePower(true)}
                >
                  Power to High Confidence
                </Button>
              </li>
              <li className="list-group-item">
                <Button
                  className="btn mr-3 my-3"
                  onClick={() => handleChangePower(false)}
                >
                  Power to Low Confidence
                </Button>
              </li>
            </ul>
          </div>
          <div className="card col m-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <Button
                  className="btn mr-3 my-3"
                  onClick={() => handleChangeHeartRate(true)}
                >
                  HR to High Confidence
                </Button>
              </li>
              <li className="list-group-item">
                <Button
                  className="btn mr-3 my-3"
                  onClick={() => handleChangeHeartRate(false)}
                >
                  HR to Low Confidence
                </Button>
              </li>
            </ul>
          </div>
        </div>

        <div className="row mt-3">
          <h1 className="h3">Grafana settings</h1>
          <form onSubmit={saveUISettings}>
            <div className="row">
              <div className="col-6">
                <label htmlFor="basic-url" className="form-label">Grafana local IP address:</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleGrafanaInput}
                    value={grafanaField}
                  />
                </div>
              </div>
              <div className="col-6">

                <label htmlFor="basic-url" className="form-label">Speed link</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleSpeedInput}
                    value={speedField}
                  />
                </div>
              </div>
              <div className="col-6">

                <label htmlFor="basic-url" className="form-label">Cadence link</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleCadenceInput}
                    value={cadenceField}
                  />
                </div>
              </div>
              <div className="col-6">

                <label htmlFor="basic-url" className="form-label">Power link</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handlePowerInput}
                    value={powerField}
                  />
                </div>
              </div>
              <div className="col-6">

                <label htmlFor="basic-url" className="form-label">Hr link</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleHRInput}
                    value={hrField}
                  />
                </div>
              </div>
              <input
                className="btn btn-outline-secondary"
                type="submit"
                value="Submit"
              />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </Layout>
  );
}
