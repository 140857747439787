import { Tabs } from '@awsui/components-react';
import React, { useMemo, useState, useEffect } from 'react';

import { EventRacesResults } from './EventRacesResults';
import { RiderEventsStats } from '../RiderResults/RiderEventsStats';

export function EventRacesResultsContainer({
  riderId,
  riderDetails,
  riderStats,
  fetchRiderStats,
}) {
  const [activeTabId, setActiveTabId] = useState();

  useEffect(() => {
    fetchRiderStats(riderId, activeTabId);
  }, [riderId, activeTabId]);

  const riderRacesGroupedByYearRaceEvent = useMemo(() => {
    if (!riderDetails?.riderResultsInRaces?.items?.length) {
      return {};
    }
    const dataObj = {};
    riderDetails.riderResultsInRaces.items.map(
      (current) => {
        const prevItem = dataObj;
        const year = new Date(current.stats?.EventTimeStamp).getFullYear();
        const allTime = 1; // representation of all times
        const raceId = current.stats?.RaceID;
        const eventId = current.stats?.EventID;

        if (year && !dataObj[year]) {
          prevItem[year] = {};
        }
        if (year && eventId && !dataObj[year][eventId]) {
          prevItem[year][eventId] = {};
        }
        if (!dataObj[allTime]) {
          prevItem[allTime] = {};
        }
        if (eventId && !dataObj[allTime][eventId]) {
          prevItem[allTime][eventId] = {};
        }

        if (raceId) {
          prevItem[year][eventId][raceId] = current.stats;
          prevItem[allTime][eventId][raceId] = current.stats;
        }

        return prevItem;
      },
      {},
    );
    return dataObj;
  }, [riderDetails?.riderResultsInRaces?.items]);

  return (
    <div className="flex relative flex-col">
      {Object.keys(riderRacesGroupedByYearRaceEvent)?.length ? (
        <Tabs
          tabs={Object.keys(riderRacesGroupedByYearRaceEvent)
            .map((keyYear) => ({
              label: keyYear === '1' ? 'All Times' : keyYear,
              id: keyYear,
              key: keyYear,
              content: (
                <div className="mt-3">
                  <div className="text-gray-dark text-3xl font-extrabold w-100 grid grid-cols-2 justify-between mb-5">
                    {keyYear === '1' ? 'All Times' : keyYear}
                  </div>
                  <div>
                    <RiderEventsStats riderStats={riderStats} />
                  </div>
                  {Object
                    .keys(riderRacesGroupedByYearRaceEvent[keyYear]).map((keyEventId) => {
                      const currEventRaces = riderRacesGroupedByYearRaceEvent[keyYear][keyEventId];
                      return (
                        <EventRacesResults
                          eventId={keyEventId}
                          eventRaces={currEventRaces}
                          riderId={riderId}
                          riderWeight={riderDetails.rider?.WeightKg || 0}
                        />
                      );
                    })}
                </div>
              ),
            }))}
          className="events-tabs"
          activeTabId={activeTabId}
          onChange={({ detail }) => {
            setActiveTabId(detail.activeTabId);
          }}
        />
      ) : null}
    </div>
  );
}
