import { all, fork } from '@redux-saga/core/effects';
import { apiSagas } from './api.sagas';

// eslint-disable-next-line func-names
export const rootSagas = function* () {
  yield all([
    fork(
      apiSagas().watcher,
    ),
  ]);
};
