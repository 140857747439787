import gql from 'graphql-tag';

export default gql(`
query($eventId: String!) {
  getLiveRace(eventId: $eventId) {
    Race {
      RaceName
      RaceID
    }
    Round {
      Round 
      Message 
      League 
      RaceType 
      RoundName 
      SeasonID 
      Duration 
      NoOfHeatsInRound 
      sk 
      EventID 
      pk 
      StartTime 
      RacesInRound {
        NoOfRiders
        Heat
        RaceID
        Riders {
          Bib
          Distance
          EventID
          FirstName
          Gender
          Heat
          Laps
          LastName
          League
          Message
          NOC
          RaceID
          RaceLaps
          RaceName
          RaceSpeed
          RaceTime
          RaceType
          Rank
          Round
          SeasonID
          ShortTVName
          State
          Status
          Team
          TimeStamp
          TotalHeats
          TotalRounds
          UCIID
          Diff
        }
        RidersUCIID
      }
    }
    Laps {
      Message
      KinesisAnalyticsIngestTime
      LapsToGo
      ServerTimeStamp
      DynamoIngestTime
      RaceID
      SeasonID
      sk
      EventID
      pk
      ApiIngestTime
      DistanceToGo
    }
  }
}`);
