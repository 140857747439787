import React, { useState } from 'react';
import { Button } from '@awsui/components-react';

import {
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

// To filter individual columns
// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}) {
  const ref = React.useRef();

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={`${className} cursor-pointer`}
      {...rest}
    />
  );
}

export function NewGrid({
  columns,
  data,
  displayAllRows,
  isRowSelectionEnabled = false,
}) {
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [sorting, setSorting] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState([]);
  const [isColumnSelectionVisible, setColumnSelectionVisibility] = useState(false);

  const onRowSelectionChangeHandler = (items) => {
    setRowSelection(items);
  };

  let table;

  if (!displayAllRows) {
    table = useReactTable({
      data,
      columns,
      filterFns: {
        fuzzy: fuzzyFilter,
      },
      state: {
        columnFilters,
        globalFilter,
        sorting,
        rowSelection,
      },
      enableRowSelection: isRowSelectionEnabled,
      onRowSelectionChange: onRowSelectionChangeHandler,
      onSortingChange: setSorting,
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      globalFilterFn: fuzzyFilter,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
      getFacetedMinMaxValues: getFacetedMinMaxValues(),
    });
  } else {
    table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
    });
  }

  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'Rank') {
      if (table.getState().sorting[0]?.id !== 'Rank') {
        table.setSorting([{
          id: 'Rank',
          desc: false,
        }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);

  if (!columns?.length || !data?.length) {
    return (
      <div className="alert alert-warning" role="alert">
        No results
      </div>
    );
  }

  return (
    <div className="p-2 mt-4">
      {!displayAllRows && (
        <>
          {isColumnSelectionVisible && (
            <div className="inline-flex w-100 justify-between mb-3">
              <div className="px-1">
                <label>
                  <input
                    {...{
                      type: 'checkbox',
                      checked: table.getIsAllColumnsVisible(),
                      onChange: table.getToggleAllColumnsVisibilityHandler(),
                    }}
                  />
                  {' '}
                  Toggle All
                </label>
              </div>
              {table.getAllLeafColumns()
                .map((column) => (
                  <div key={column.id} className="px-1">
                    <label>
                      <input
                        {...{
                          type: 'checkbox',
                          checked: column.getIsVisible(),
                          onChange: column.getToggleVisibilityHandler(),
                        }}
                      />
                      {' '}
                      {column.id}
                    </label>
                  </div>
                ))}
            </div>
          )}
          <div className="inline-flex justify-between w-100">
            <div className="w-100 inline-flex justify-start">
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={(value) => setGlobalFilter(String(value))}
                className="p-2 font-lg shadow border border-block w-50"
                placeholder="Search all columns..."
              />
            </div>
            <div className="w-100 inline-flex justify-end">
              <Button
                className="btn h-8 ml-16"
                style={{ width: '104px' }}
                onClick={() => {
                  setColumnSelectionVisibility((prev) => !prev);
                }}
              >
                {isColumnSelectionVisible ? 'Hide ' : 'Show '}
                Column Controls
              </Button>
            </div>
          </div>
        </>
      )}
      <div className="h-2" />
      <table
        className="table table-striped table-hover text-left table-bordered mt-3"
        {...{
          style: {
            width: '100%',
          },
        }}
      >
        <thead>
          {table.getHeaderGroups()
            .map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
        </thead>
        <tbody>
          {table.getRowModel()
            .rows
            .map((row) => (
              <tr key={row.id} className="m-3">
                {row.getVisibleCells()
                  .map((cell) => (
                    <td key={cell.id} className="py-3">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
      {/* <div className="flex justify-end">
        Showing {table.getPrePaginationRowModel().rows.length} results
      </div> */}
      <div className="h-2" />
      {!displayAllRows && data.length > 10 && (
        <div className="flex justify-center items-center flex-col gap-2">
          <div>
            <button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </div>
          <div>
            <span className="flex items-center gap-1">
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1}
                {' '}
                of
                {' '}
                {table.getPageCount()}
              </strong>
            </span>
          </div>

          {/* <div>
            <span className="flex items-center gap-1">
              Go to page:
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className="border p-1 rounded w-10"
              />
            </span>
          </div> */}
          <div>
            <select
              className="border rounded p-1"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show
                  {' '}
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
}

// Legacy Grid Component
function Grid({
  cols,
  results,
}) {
  if (!cols?.length || !results?.length) {
    return (
      <div className="alert alert-warning" role="alert">
        No results
      </div>
    );
  }
  return (
    <table className="table table-striped table-hover text-left table-bordered mt-3">
      <thead>
        <tr>
          {cols.map((col) => (
            <th key={col.colName}>{col.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {results.map((result) => (
          <tr className="m-3">
            {cols.map(({
              colName,
              children,
              format,
            }) => (
              <td className="py-3">
                {children
                  ? children(result)
                  : format?.(result[colName], result) || result[colName]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Grid;
