import React from 'react';
import { Container, Header } from '@awsui/components-react';

import './LineChart.css';

export default function LineChartGraphana(props) {
  const {
    label,
    url,
  } = props;

  return (
    <div className="lineChartWrapper">
      <Container
        header={(
          <Header variant="h2" description="">
            {label}
          </Header>
        )}
      >
        <iframe
          src={url}
          width="100%"
          height={600}
          frameBorder={0}
          title="a"
        />
      </Container>
    </div>
  );
}
