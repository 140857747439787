import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { isAdminUser } from './services/user';

import {
  CamSettingsPage,
  DataConfidence,
  Error404,
  Event,
  Events,
  LiveRace,
  Questioner,
  RaceDashboard,
  RaceResults,
  RaceStartList,
  RiderInfo,
  RiderList,
  UserSettings,
  RidersRaceComparison,
} from './views';
import { Standings } from './views/Standings';

// eslint-disable-next-line import/no-extraneous-dependencies
const md5 = require('md5');

const routes = [
  {
    path: '/',
    component: Events,
    exact: true,
    isAdmin: false,
  },
  {
    path: '/riders',
    component: RiderList,
    exact: true,
    isAdmin: false,
  },
  {
    path: '/rider/:riderId',
    component: RiderInfo,
    exact: true,
    isAdmin: false,
  },
  {
    path: '/rider/:riderId/comparedRiderId/:comparedRiderUciId',
    component: RiderInfo,
    exact: true,
    isAdmin: false,
  },
  {
    path: '/race/:raceId/ridersComparison/:rider1/:rider2',
    component: RidersRaceComparison,
    exact: true,
    isAdmin: false,
  },
  {
    path: '/race/:eventId/:raceId/live',
    component: LiveRace,
    exact: true,
    isAdmin: false,
  },
  {
    path: '/race/:eventId/:raceId/start-list',
    component: RaceStartList,
    exact: true,
    isAdmin: false,
  },
  {
    path: '/race/:eventId/:raceId/results',
    component: RaceResults,
    exact: true,
    isAdmin: false,
  },
  {
    path: '/event/:eventId',
    component: Event,
    exact: false,
    isAdmin: false,
  },
  {
    path: '/race/:eventId/:raceId/race',
    component: RaceDashboard,
    exact: false,
    isAdmin: false,
  },
  {
    path: '/questioner',
    component: Questioner,
    exact: false,
    isAdmin: false,
  },
  {
    path: '/user-settings',
    component: UserSettings,
    exact: false,
    isAdmin: false,
  },
  {
    path: '/data-confidence',
    component: DataConfidence,
    exact: false,
    isAdmin: true,
  },
  {
    path: '/cam-settings',
    component: CamSettingsPage,
    exact: false,
    isAdmin: true,
  },
  {
    path: '/standings',
    component: Standings,
    exact: false,
    isAdmin: true,
  },
];

export default function AppRouter() {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    (async () => {
      setIsAdmin(await isAdminUser());
    })();
  }, []);

  const filteredRoutes = routes.filter(
    (item) => !item.isAdmin || item.isAdmin === isAdmin,
  );

  return (
    <Switch>
      {filteredRoutes.map((route, i) => (
        <Route
          key={md5(`switch${i}`)}
          exact={route.exact}
          path={route.path}
          component={route.component}
        />
      ))}

      <Route>
        <Error404 />
      </Route>
    </Switch>
  );
}
