import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

export const useHookParam = (paramName, operation) => {
  const params = useParams();
  const param = params[paramName];
  const current = operation?.current;

  const match = useMemo(() => {
    if (!current?.length) {
      return undefined;
    }
    return current.filter((item) => {
      if (paramName === 'raceId') {
        return item.RaceID === param;
      }
      if (paramName === 'eventId') {
        return item.eventId === param;
      }
      return false;
    })?.[0];
  }, [current, param, paramName]);

  return {
    match,
    param,
  };
};
